.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1 !important;
}
@-webkit-keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}
@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}
.popup-inner {
    position: relative;
    padding: 30px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border: 2px dashed red;
    display: flex;
    justify-content: center;
    flex-direction: column;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.8s
}
.popup-inner .close-btn{
    position: absolute;
    top: -17px;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: none;
    border: none;
    background-color: #003558;
    padding: 7px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.close-btn img{
    width: 100%;
}
.section-before-google-login{
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
}
.heading-inner-modal{
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1;
}
.heading-inner-modal span{
    color: #2071bc;
    font-weight: 600;
    font-style: italic;
}
.google-login-button{
    opacity: 1 !important; 
}
/* arrow animation css */
.arrow-container {
    width: 25px;    
    animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    cursor: pointer;
    height: 20px;
    margin: 0 auto; 
    padding-top: 25px;    
}
.main-section-animation-arrow{
    width: 50px;
    height: 50px;
    background-color: #fff;
    margin: 0 auto;
    margin-bottom: 30px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 50%);
} 
.arrow-down {
  height: 6px;
  background: #003558;
  transform: rotate(45deg);
  transform-origin: 0% 0%;
  border-radius: 5px;
}
.arrow-down:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 100%;
  border-radius: 5px;
  background: #003558;
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
}  
@keyframes bounce {
50% {
    transform: translateY(-15px);
    }
  }
  /* media queries */
  @media only screen and (min-width:320px) and (max-width:767px) {
    .popup-inner{
        width: 85%;
    }
  }