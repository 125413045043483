* {
    font-family: Verdana, Geneva, sans-serif;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

.mb-20 {
    margin-bottom: 20px;
}

.StartTime-profile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-button-header {
    background: #0071bc;
    border: none;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 18px;
}

.login-button-header:hover {
    background-color: #09466e;
    transition: .3s ease-in-out;
}

.header-sec {
    position: relative;
    width: 100%;
    float: left;
    display: block;
    -webkit-box-shadow: 2px 1px 6px -1px rgb(0 0 0 / 29%);
    -moz-box-shadow: 2px 1px 6px -1px rgb(0 0 0 / 29%);
    box-shadow: 2px 1px 6px -1px rgb(0 0 0 / 29%);
    padding-bottom: 20px;
    margin-bottom: 3px;
}


.header-sec .hdr-logo .logo-ps img {
    width: 159px;
    margin-top: 20px;
}

.header-sec .hdr-btn .btn-log {
    float: right;
    font-size: 20px;
    color: #3B3B3B;
    margin-top: 26px;
}

.header-bg {
    width: 100%;
    float: left;
    position: relative;
}

.bnr-sec {
    width: 100%;
    float: left;
    margin-top: 180px;
}

.bnr-sec .bnr-text h4 {
    color: #3B3B3B;
    font-size: 30px;
    line-height: 36px;
}

.bnr-sec .bnr-text h1 {
    color: #3B3B3B;
    font-size: 50px;
    line-height: 60px;
}

.bnr-sec .bnr-text .spclist {
    color: #0071BC;
    font-weight: 700;
}

.bnr-input {
    width: 100%;
    float: left;
    display: block;
    text-align: center;
    position: absolute;
    top: 463px;
}

.bnr-input .input-type {
    width: 798px;
    height: 66px;
    border-radius: 41px;
    border: 0;
    -webkit-box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    padding: 10px 60px 10px 32px;
    outline: 0;
    color: rgb(59 59 59 / 52%);
    font-size: 20px;
}

.bnr-input .bnr-inputIcone {
    position: relative;
    width: 798px;
    margin: 0 auto;
}

.bnr-input .bnr-inputIcone .cls-icon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    font-size: 22px;
    right: 20px;
    color: #3B3B3B;
}

.dashbord-strt {
    width: 100%;
    float: left;
    padding: 100px 0px;
    flex-grow: 1;
}

.dashbord-strt .dashbord-box,
.admin-dashbord-box {
    padding: 33px 16px 27px;
    border: 1px solid rgb(243 243 243 / 24%);
    background-color: #F3F3F30D;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    height: 380px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.admin-dashbord-box {
    height: 400px;
}

.new-responsive-ask {
    position: absolute;
    bottom: 10px;
    width: 90%;
}

.dashbord-strt .dashbord-box h1,
.dashbord-strt .admin-dashbord-box h1 {
    font-size: 19px;
    font-weight: 800;
    margin-bottom: 0;
    color: #0071BC;
}

.dashbord-strt .dashbord-box p,
.dashbord-strt .admin-dashbord-box p {
    font-size: 14px;
    color: #313131;
}

.dashbord-strt .dashbord-box .clr-chng,
.dashbord-strt .admin-dashbord-box .clr-chng {
    color: #7D7D7D;
    width: 100%;
    word-break: break-word;
}

.dashbord-strt .dashbord-box .btn-qstn,
.dashbord-strt .admin-dashbord-box .btn-qstn {
    font-size: 13px;
    color: #FFFFFF;
    background-color: #3B3B3B;
    padding: 8px 0px;
    border-radius: 21px;
    border: 1px solid #3B3B3B;
    margin-top: 38px;
    display: block;
    cursor: pointer;
    width: 100%;
}

.ps-footer {
    width: 100%;
    float: left;
    background-color: #689cca61;
    text-align: center;
    padding: 20px 0 0px 0;
}

.ps-footer p {
    background: #0D589D;
    color: #ffffffd1;
    font-size: 12px;
    line-height: 27px;
    margin-bottom: 0;
    padding: 10px;
}


/* Modal Css */
.modl-PS .modal-header {
    border: 0;
    padding: 0;
}

.modl-PS .modal-header .btn-close {
    position: absolute;
    right: -8px;
    top: -7px;
    background-color: #fff;
    opacity: 1;
    border-radius: 50px;
    padding: 10px;
    font-size: 11px;
    -webkit-box-shadow: 4px 8px 28px -7px rgb(0 0 0 / 82%);
    -moz-box-shadow: 4px 8px 28px -7px rgba(0, 0, 0, 0.82);
    box-shadow: 4px 8px 28px -7px rgb(0 0 0 / 82%);
    z-index: 1;
}

.modl-PS .mdl-left {
    margin-right: 30px;
    width: 20%;
    float: left;
}

.modl-PS .mdl-text-bx {
    float: left;
    width: 70%;
}

.modl-PS .ulmodal .ul-list {
    list-style: none;
    display: inline-flex;
    padding: 0;
    margin-top: 20px;
    width: 100%;
}

.modl-PS .ulmodal .ul-list li a {
    margin-right: 8px;
    border: 1px solid #707070;
    padding: 6px 15px;
    border-radius: 15px;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #003558;
}

.modl-PS {
    margin-right: 8px;
    border: 1px solid #000;
    padding: 6px 15px;
    border-radius: 11px;
    font-size: 12px;
    color: #000;
}

.modl-PS .mdl-text-bx h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.modl-PS .mdl-text-bx p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #7D7D7D;
    width: 100%;
    display: block;
    float: left;
    word-wrap: break-word;
}

.bredcrum-ps {
    width: 100%;
    float: left;
    padding-top: 30px;
    background-color: #f7fbfd;
}

.bredcrum-ps .breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, ">");
}

.bredcrum-ps .breadcrumb-item {
    float: left;
    line-height: 21px;
    font-size: 14px;
    color: #313131;
}

.quries-clrfix {
    width: 100%;
    float: left;
    display: flex;
    background-color: #0071BC08;
    flex-grow: 1;
    padding-bottom: 40px;
    align-items: center;
}

.second-queries-clrfix {
    height: auto;
}

.new-queries-clrfix {
    width: 100%;
    float: left;
    background-color: #0071BC08;
    height: 100%;
}

.no-new-queries-clrfix {
    height: 60vh;
}

.delete-icon-query-final {
    position: absolute;
    right: -58px;
    cursor: pointer;
    bottom: -30px;
    font-size: 18px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
    z-index: 1;
}

.edit-icon-query {
    position: absolute;
    right: -30px;
    cursor: pointer;
    bottom: -30px;
    font-size: 18px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
    z-index: 1;
}

.new-height-private {
    height: auto;
}

.auto-height-private {
    height: 40vh;
}

.no-query-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-query-section-two {
    width: 560px;
    text-align: center;
    border: 1px solid #003558;
    padding: 51px;
    border-radius: 10px;
    box-shadow: 13px 14px 0px 0px #003558;
}

.no-query-section-two h2 {
    color: orange;
    font-size: 19px;
}

.no-query-link {
    padding: 5px 15px;
    border-radius: 30px;
    background: orange;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.no-query-link:hover {
    color: #fff;
}

.quries-clrfix .wt-clrfix {
    font-size: 15px;
    line-height: 19px;
    color: #313131;
    border: 1px solid #0071BC;
    -webkit-box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    padding: 14px 16px;
}

.quries-clrfix .btn-radio {
    width: 100%;
    float: left;
}

.quries-clrfix .btn-radio .btn-radio-box-post {
    float: left;
    padding-left: 15px;
}

.quries-clrfix .btn-radio .form-check {
    font-size: 14px;
}

.quries-clrfix .btn-radio .btn-radio-box .inpt-txt {
    font-size: 18px;
    color: #313131;
    line-height: 22px;
}

.quries-clrfix .btn-submit {
    font-size: 17px;
    padding: 8px 18px;
    line-height: 20px;
    float: right;
    margin-right: 10px;
}

.quries-clrfix .slct-name {
    margin-top: 15px;
    float: left;
    width: 100%;
}

.quries-clrfix .slct-name div {
    list-style: none;
    padding: 0;
    display: flex;
}

.quries-clrfix .slct-name div li a {
    -webkit-box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    background-color: #ffffff;
    border: 1px solid #2167AC61;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 28px;
    color: #3B3B3B;
    font-size: 15px;
    line-height: 21px;
    min-width: 125px;
    display: block;
    text-align: center;
}

.quries-clrfix .align-itm {
    align-items: center;
    display: flex;
}

.align-itm .label-style {
    font-size: 18px;
    line-height: 22px;
    color: #313131;
}

.sidebar-ps {
    width: 100%;
    float: left;
    background-color: #EEEEEE;
    padding: 0 13px;
    position: relative;
    top: 0px;
    height: 85vh;
    overflow-y: scroll;
    margin-bottom: 30px;
}

.sidebar-ps .gold-mmbr {
    display: none;
    font-size: 19px;
    color: #FFFFFF;
    background-color: #FF9226;
    text-align: center;
    font-weight: 700;
    padding: 17px 0;
    position: sticky;
    top: 0;
    z-index: 1;
}


.sidebar-ps .siderbar-box {
    margin-bottom: 10px;
    background-color: #FFFFFF;
    padding: 12px 0 10px 0;
    -webkit-box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 2px 13px -5px rgba(0, 0, 0, 0.29);
    border-radius: 7px;
}

.sidebar-ps .siderbar-box h2 {
    font-size: 16px;
    color: #3B3B3B;
    font-weight: 600;
}

.sidebar-ps .siderbar-box p {
    font-size: 13px;
    color: #7D7D7D;
    line-height: 16px;
    margin-bottom: 0.5rem;
}

.ps-name-box {
    margin-top: 20px;
    border-radius: 21px;
    padding: 15px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 29%);
    position: relative;
    height: 100%;
    background: linear-gradient(90deg, transparent, #0000ff1c);
}

.anonymous-img-queries {
    width: 18px;
    position: absolute;
    top: 0;
    right: 6px;
    cursor: pointer;
}

.new-private-img-queries {
    right: 30px;
    width: 18px;
    height: 18px;
}

.ps-name-box .title-text {
    width: 80%;
    float: left;
    position: relative;
}

.ps-name-box .title-text h1 {
    font-size: 15px;
    line-height: 20px;
    color: #313131;
    font-weight: 700;
}

.ps-name-box .title-text p {
    font-size: 13px;
    line-height: 20px;
    color: #313131;
    margin-bottom: 5px !important;
}

.ps-name-box .title-text .like-btn {
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    margin-right: 5px;
    font-weight: 800;
    color: #3B3B3B;
}

.already-spam-icon {
    position: absolute;
    right: 40px;
}

.like-count {
    margin-right: 10px;
    font-size: 15px;
}

.comment-icon {
    margin-right: 10px !important;
    font-size: 15px;
}

.ps-name-box .title-text .like-btn.active {
    color: #0071BC;
}

.ps-name-box .title-text .undrline-txt {
    opacity: 052;
    color: #0071BC;
    font-size: 13px;
    float: right;
}

.ps-name-box .box-img {
    float: left;
    width: 13%;
    margin-right: 3%;
}

.ps-name-box .title-text.title-text-right {
    margin: 20px 0;
}

.ps-name-box .todiv-ps {
    float: left;
    width: 83%;
    margin: 40px 0 0px 0px;
}

.ml-20 {
    margin-left: 15px;
}

.ps-name-box .input-grp-fil .frm-cntrol {
    border: 1px solid #8D8D8D;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 15px;
    height: 50px;
    color: #313131;
}

.ps-name-box .input-grp-fil .input-group-text {
    border: 1px solid #8D8D8D;
    border-radius: 5px;
    padding: 8px 18px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    background-color: #0071bc;
    color: #FFFFFF;
}

.modl-PS .modal-dialog {
    max-width: 650px;
}

.article-ps {
    width: 100%;
    float: left;
    flex-grow: 1;
    padding-bottom: 40px;
    background-color: #f7fbfd;
    cursor: pointer;
}

.article-ps .article-box h2 {
    color: #313131;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.article-ps .article-box p {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    font-family: Georgia, 'Times New Roman', Times;
}

.article-ps .article-box .article-bx-inr img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.add-flex-or-more {
    display: flex;
    justify-content: center;
    align-items: center;

}

.flex-center-add {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.flex-center-add div {
    display: flex;
    align-items: center;
}

.article-ps .article-box .grl-musicn {
    width: 100%;
    border-radius: 0px;
    height: 220px;
}

.image-user-article {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.profilename-heading {
    width: auto !important;
}

.article-content-view p {
    font-size: 16px !important;
    text-align: justify;
}

.article-content-view {
    margin-top: 2rem;
}

.articleview-heading {
    color: #0000008f;
    font-size: 13px;
}

.article-view-title {
    margin-bottom: 20px;
    text-transform: capitalize;
    text-align: center;
    font-weight: 700;
    margin-top: 20px;
    font-size: 35px;
    font-family: initial;
}

.edit-article-button {
    position: absolute;
    right: 30px;
    top: 10px;
    background: #0071bc;
    color: #fff;
    outline: none;
    border: none;
    padding: 5px 20px;
    border-radius: 6px;
}

.section-user-info {
    margin: 0px !important;
    display: flex;
    align-items: center;
    justify-content: end;
}

.section-user-info div {
    margin-right: 10px;
}

.article-ps .article-box {
    padding-bottom: 15px;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 40%);
    height: 480px;
    position: relative;
    margin: 10px 20px;
}

.view-article-box {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.article-ps .article-box .readmore {
    padding: 3px 6px;
    font-size: 14px;
    min-width: 85px;
}

.article-ps .article-box .article-bx-inr {
    float: left;
    position: absolute;
    bottom: 20px;
    padding: 0px 15px;
}

.article-ps .article-box .article-bx-inr .linx-mster {
    width: 30%;
}

.article-ps .article-box .article-bx-inr h5 {
    color: #0071BC;
    font-size: 16px;
    padding: 5px 10px;
    font-weight: 600;
    font-family: 'Times New Roman', Times;
}

.article-ps .article-box .article-bx-inr span {
    float: left;
    font-size: 13px;
    color: #313131;
}

.article-ps .article-box .pding-20 {
    padding: 20px;
}

.eat-ps {
    width: 100%;
    float: left;
    background-color: rgb(247 251 253);
    flex-grow: 1;
    padding-bottom: 40px;
}

.eat-ps .eatTop span {
    color: #0b3558;
}

.eat-ps .eatTop .dr-riya {
    color: #313131;
    font-size: 13px;
}

.eatps-box {
    display: flex;
    justify-content: center;
}

.eatps-box div {
    margin-left: 30px;
}

.eat-ps .eatps-box h1 {
    font-size: 40px;
    color: #313131;
    line-height: 49px;
    font-weight: 700;
    margin-top: 10px;
    text-transform: capitalize;
}

.eat-ps .eatps-box .adequat-img {
    border-radius: 10px;
    height: 400px;
    object-fit: contain;
}

.quris-tpRight {
    margin-top: 10px;
}

.main-section-before-username {
    margin-top: 5px;
}

.main-logo-header-profile {
    float: left;
    border: 2px solid #0071BC;
    border-radius: 50%;
    padding: 2px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
}

.quris-tpRight h4 {
    font-size: 18px;
    color: #0071BC;
    line-height: 0px;
    float: left;
    width: 60%;
    margin-top: 10px;
    margin-bottom: 15px;
}

.quris-tpRight span {
    font-size: 16px;
    color: #313131;
    line-height: 10px;
    float: left;
}

.set-main-psn-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-main-cards-dshbord {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 10px;
    float: left;
    margin-right: 3%;
    object-fit: cover;
}


.dropdown-toggle {
    position: absolute;
    background-color: transparent;
    border: none;
    color: #000;
}

.btn-primary:focus {
    box-shadow: none;
    background-color: #0d6efd;
    color: #fff;
}

.btn-primary:hover {
    background-color: #0d6efd !important;
    color: #fff;
}

.btn-primary:active {
    background-color: #0d6efd !important;
    color: #fff;
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.logout-image {
    border: none !important;
    width: 25px !important;
    margin-right: 12px;
}

.services-add-preview {
    cursor: pointer;
    position: absolute;
    background-color: #000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0px 0px;
    top: -10px;
    right: -8px;
    opacity: 1;
    z-index: 1;
    line-height: 17px;
    font-size: 10px;
}

.cross-icon-service {
    width: 12px;
}

.button-add-service {
    margin-left: 10px;
    border: none;
    background: #000;
    color: #fff;
    padding: 12px 20px;
    border-radius: 5px;
}

.input-services-services input {
    width: 100% !important;
    padding: 10px;
}

.input-services-services div {
    width: 70%;
    display: block;
    position: unset !important;
    left: 0 !important;
}

.input-services-services div div {
    max-height: 260px !important;
}

.input-services-services div div div {
    padding: 2px 15px !important;
    width: 100% !important;
}

.input-services-services input div {
    position: unset;
    left: 0;
    right: 0;
}

.box-card-user-dsbr {
    cursor: pointer;
}

.m-none {
    margin: 0;
}

.main-image-modal {
    width: 100px;
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
}

.add-mb {
    margin-bottom: 20px;
}

.set-padding {
    padding: 5px 6px !important;
    text-align: center;
    font-size: 12px !important;
}

.mb-set {
    margin-bottom: 9px;
    min-width: 130px !important;
}

.icons-queries {
    font-size: 19px;
    margin-right: 3px;
    margin-top: -3px;
    cursor: pointer;
}

.input-services-services {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.user-img-queries-sidebar {
    width: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
    height: 70px;
    cursor: pointer;
    object-fit: cover;
}

.main-section-editor {
    width: 100%;
    display: block;
}

.btn-qstn a:hover {
    color: #fff !important;
}

.username-sidebar-cards {
    cursor: pointer;
}

.css-gnv4r8 {
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    top: 20px;
    left: 783px;
    border-color: #fff !important;
}

.button-loader {
    padding: 5px 0px;
}

.mb-40 {
    margin-bottom: 22px !important;
}

.css-rn1dpj {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: 16px;
    right: 3px;
    color: #fff !important;
}

.cross-icon-service-queries {
    width: 12px;
    margin-top: 0px;
    top: 0;
    color: #fff;
}

.users-add-preview {
    cursor: pointer;
    position: absolute;
    background-color: #000;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 0px;
    top: -10px;
    right: 7px;
    opacity: 1;
    z-index: 1;
}

.outer-cross-queries {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
}

.user-queries-preview {
    position: relative;
}

.change-dashboard {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
    float: unset;
    margin-right: 0;
    object-fit: cover;
}

.add-position {
    position: relative;
    margin-bottom: 50px;
}

.search-input-sidebar {
    padding: 10px 3px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 2px 6px -1px #a7a3a3;
    color: rgba(59, 59, 59, .52);
    width: 100%;
    margin-top: 10px;
}

.main-section-search-sidebar {
    position: relative;
    margin-bottom: 10px;
}

.search-input-sidebar:focus-visible {
    outline: none;
}

.icon-search-sidebar {
    position: absolute;
    right: 10px;
    top: 24px;
    font-size: 15px;
}

.add-mr-right {
    margin-right: 10px;
}

.cross-icon-queries-reply {
    width: 22px;
    height: 22px;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    top: -10px;
    right: -5px;
}

.add-position-reply-queries {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.query-reply-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.cross-reply-query {
    width: 12px;
}

.add-float {
    width: 100%;
    display: block;
    float: left;
}

/* new header css */
.padding-20-header {
    padding: 10px;
}

.logo-section-header {
    width: 150px;
    display: block;
    float: left;

}

.logo-section-header img {
    width: 100%;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle {
    position: unset;
    border-radius: 50% !important;
}

.dropdown-image-icon {
    border-radius: 50%;
    width: 95%;
    height: 95%;
    object-fit: cover;
}

.nav-link {
    padding: 0px;
    width: 50px;
    height: 50px;
}

.dropdown-menu[data-bs-popper] {
    left: -35px !important;
}

.spam-mark-icon {
    display: flex;
    justify-content: space-between;
}

.spam-icon {
    font-size: 24px;
    cursor: pointer;
}

.spam-modal {
    display: flex;
    justify-content: space-between;
}

.modal-spam-input {
    width: 100%;
    height: 40px;
    padding: 0px 5px;
    border-radius: 5px;
}

.modal-spam-btn-cont {
    display: flex;
    justify-content: center;
    width: 100%;
}

.model-spam-button {
    height: 42px;
    width: 120px;
    background: blue;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.modal-cross-button {
    cursor: pointer;
}

.spam-heading {
    font-size: 20px;
}

.button-spam {
    font-size: 11px;
    background: transparent;
    border: 1px solid #000;
    padding: 2px 6px;
    border-radius: 5px;
    color: #0071BC;
    position: absolute;
    right: 25px;
}

.button-spam-discussion {
    font-size: 12px;
    background: transparent;
    border: 1px solid #000;
    padding: 2px 6px;
    border-radius: 5px;
    color: #0071BC;
    position: absolute;
    right: 25px;
}

.spam-fill-outer {
    background-color: red;
    padding: 5px 20px;
    border-bottom-left-radius: 6px;
    position: absolute;
    top: -1px;
    right: 20px;
    border-bottom-right-radius: 6px;
}

.spam-heading-fill {
    color: #fff;
}

.modal-spam-sectio {
    width: 100%;
    display: block;
    float: left;
}

.why-spam-heading {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}

.why-spam-heading span {
    color: #0071bc;
}

.spam-response-box {
    margin-bottom: 30px;
}

.spam-response-box:focus {
    border: 1px solid #ced4da !important;
    box-shadow: unset !important;
}

.spam_submit_button {
    padding: 10px 30px;
    font-size: 18px;
    border: none;
    background-color: #0071bc;
    color: #fff;
    border-radius: 5px;
}

.spam-button-end {
    display: flex;
    justify-content: end;
}


.gold-heading {
    border-left: 70px solid transparent;
    border-right: 0px solid transparent;
    border-top: 70px solid #FF9226;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.inner-gold-heading {
    position: absolute;
    bottom: 36px;
    right: 1px;
    font-size: 18px;
    transform: rotate(45deg);
    font-weight: 600;
}

.member-sidebar-resp {
    position: absolute;
    right: 20px;
    width: 27% !important;
}

.userquery-tab-container {
    position: relative;
}

.userquery-tab-container ul {
    border: none;
}

.userquery-tab-container ul li {
    margin-right: 1px;
}

.userquery-tab-container ul li button {
    width: 100%;
    padding: 0px 15px;
    height: 45px;
}

.userquery-tab-container ul li .nav-link {
    color: #fff;
    background-color: #137bc1;
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.userquery-tab-container ul li .nav-link.active {
    color: #fff;
    background-color: #137bc1;
    border: none;
    border-top: 8px solid #137bc1;
    border-bottom: 8px solid orange;
}

.profile-service-row {
    display: flex;
    flex-wrap: wrap;

}

.breadcrumb-separator {
    height: 1px;
    background: #eee;
    margin-bottom: 30px;
}

.infinitescroll-dashboard {
    display: flex;
    flex-wrap: wrap;
    column-gap: 51px;
}

.set-in-web {
    width: 22% !important;
}

.animation_dashboard {
    width: 100%;
}

.button-delete-modal {
    padding: 8px 20px;
    margin-left: 10px;
    background-color: #0071bc !important;
    border-color: #0071bc !important;
}

.button-delete-modal-No {
    padding: 8px 20px;
    margin-left: 10px;
    background-color: #FF9226 !important;
    border-color: #FF9226 !important;
}

.txtReply:focus {
    border: none;
    box-shadow: none;
}

.delete-modal-heading {
    font-size: 22px !important;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
}

.separator-query-resp {
    width: 100%;
    border-top: 1px solid #70707040;
    display: block;
    float: left;
    margin-bottom: 15px;
}


.add-bottom-position {
    bottom: -32px;
    z-index: 1;

}

.queries-reply-section-outer {
    width: 100%;
    display: block;
    float: left;
}

.members-tab-gold li #uncontrolled-tab-example-tab-Gold {
    background-color: orange;
    color: #fff;
    margin-right: 2px;
    border-bottom: 8px solid #0071BC;
}

.members-tab-gold li #uncontrolled-tab-example-tab-Gold.active {
    border-bottom: 8px solid orange;
    border-top: 8px solid orange;
}

.members-tab-gold li #uncontrolled-tab-example-tab-Other {
    background-color: #0071BC;
    color: #fff;
    border-bottom: 8px solid orange;
}

.members-tab-gold li #uncontrolled-tab-example-tab-Other.active {
    border-bottom: 8px solid #0071BC;
    border-top: 8px solid #0071BC;
}

.members-tab-gold {
    border-bottom: none;
    width: 100%;
}

.members-tab-gold li {
    width: 50%;
}

.members-tab-gold li .nav-link {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    padding: 0px 8px;
}

.btn-primary,
.btn-primary:hover {
    background-color: #0071bc !important;
    border-color: #0071bc;
}

.form-check-input:checked {
    background-color: #0071bc;
    border-color: #0071bc;
}

.participants-section {
    width: 100%;
    display: block;
}

.participants-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px #000;
}

.participants-inner-section {
    display: flex;
    align-items: center;
    margin: 4px 0px;
    width: 100%;
    background: linear-gradient(45deg, #0000ff33, #ffa50052);
    border-radius: 5px;
    padding: 5px 10px;
}

.information_section_participants {
    display: block;
    float: left;
    margin-left: 10px;
}

.information_section_participants p {
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 21px;
    color: #313131;
    font-weight: 700;
}

.outer-section-participants {
    width: 100%;
    display: block;
    float: left;
    margin-top: 7px;
    position: relative;
}

.outer-section-participants h3 {
    font-size: 13px;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    padding: 6px 9px;
    border-radius: 26px;
    color: black;
}

.outer-section-participants h3 div {
    background-color: #0071bc;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.outer-section-participants h3 div span {
    color: #fff;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.new-participant-section {
    height: auto;
    width: auto;
    background: #fff;
    border-radius: 10px;
}

.new-query-tooltip {
    opacity: 1 !important;
    inset: 0px auto auto 0px;
    transform: translate3d(252px, 631.2px, 0px);
}

.new-query-tooltip .tooltip-inner {
    max-width: 400px;
    padding: 1px 5px;
    color: #fff;
    text-align: center;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0px 0px 3px 0px #000;
}

.new-query-tooltip .tooltip-arrow {
    display: none;
}

.delete-icon-modal-query {
    position: absolute;
    right: -50px;
    cursor: pointer;
    bottom: 5px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
}

.why-spam-btn {
    display: flex;
    justify-content: center;
}

.profile-info-data-dashboard {
    word-break: break-word;
    padding: 0px 10px;
}

.discussion-link-header {
    margin-right: 40px;
    color: #0071bc;
    font-size: 14px;
    font-weight: 600;
}

.qresp-pstn {
    bottom: 50px !important;
}

.new-width-set-discussion {
    width: 100%;
}

.new-sticky-sidebar {
    position: sticky;
    top: 20px;
    overflow: hidden;
}

.mobile-sidebar-view {
    display: none;
}


.discussion-link-view {
    display: none !important;
}

.mouse-enter-participants {
    height: auto;
    width: auto;
    background-color: #fff;
    opacity: 1;
    background: #fff;
    z-index: 1;
    position: absolute;
    bottom: 0px;
    left: 112px;

}

.participant-tool-tip {
    padding: 0 4px !important;
    margin: 0 !important;
    opacity: 1 !important;
}

.respo-button-mob-spam {
    font-size: 14px;
    background: transparent;
    border: 1px solid #000;
    padding: 5px 10px;
    border-radius: 5px;
    color: #0071BC;
    position: absolute;
    right: -10px;
}



.copy-button-modal-section {
    position: absolute;
    right: 30px;
    top: 20px;
}

.copy-button-modal-section a {
    color: #fff !important;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 8px;
    background: #FF9226;
    border-radius: 5px;
}

.copied-text {
    font-size: 14px;
    margin-top: 8px;
    text-align: end;
    color: red;
}

.contact-button-modal {
    color: #fff !important;
    cursor: pointer;
    font-size: 12px;
    padding: 7px 9px;
    background: #003558;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
}

.query-text-figure figure {
    display: none;
}

.article-view-like {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.like-btn.active {
    color: #0071BC;
}

.new-name-box {
    background-color: #0071bc12;
    padding: 30px;
    border-radius: 15px;
}

.new-name-box .title-text h1 {
    font-size: 17px;
    line-height: 21px;
    color: #313131;
    font-weight: 700;
}

.new-name-box .title-text p {
    font-size: 16px;
    line-height: 26px;
    color: #313131;
    margin-bottom: 0px !important;
}

.new-name-box .title-text .undrline-txt {
    opacity: 052;
    color: #0071BC;
    font-size: 14px;
    float: right;
}

.new-name-box .input-grp-fil .frm-cntrol {
    border: 1px solid #8D8D8D;
    border-radius: 5px;
    padding: 8px 8px;
    font-size: 16px;
    height: 50px;
    color: #313131;
}

.new-name-box .input-grp-fil .input-group-text {
    border: 1px solid #8D8D8D;
    border-radius: 5px;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    background-color: #0071bc;
    color: #FFFFFF;
}

.image-new-cards-dshbord {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 10px;
    float: left;
    margin-right: 3%;
    object-fit: cover;
}

.new-image-article-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 5px;
}

/* Your-Profile page css */
.ps-profileyr {
    width: 100%;
    float: left;
    display: block;
    flex-grow: 1;
}

.profle-mndiv {
    width: 905px;
    border: 1px solid #707070;
    border-radius: 17px;
    -webkit-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.29);
    background-color: #FFFFFF;
    margin: 0 auto;
    padding: 34px 33px;
}

.ps-profileyr .profle-mndiv .prfl-img {
    width: 214px;
    border-radius: 50%;
    height: 100%;
    border: 1px solid rgb(33 103 172 / 61%);
    padding: 8px;
    height: 214px;
    object-fit: cover;
}

.ps-profileyr .profle-mndiv .edit-prfl {
    background-color: #313131;
    padding: 4px 19px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
    margin: 20px auto;
    width: 80px;
    cursor: pointer;
    position: relative;
    height: 35px;
}

.input-file-profile {
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 35px;
    width: 80px;
    font-size: 0;
}

.frm-inpt-profle .frm-inpt-inr {
    position: relative;
    margin: 20px 0;
    float: left;
    width: 100%;
    border-radius: 4px;
    -webkit-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.frm-inpt-profle .frm-inpt-inr label {
    color: #6E9CC9;
    line-height: 21px;
    position: absolute;
    top: -12px;
    left: 10px;
    background-color: #fff;
    padding: 0px 8px;
    z-index: 1;
    font-size: 15px;
}

.frm-inpt-profle .frm-inpt-inr input {
    width: 100% !important;
    float: left !important;
    padding: 14px !important;
    border-radius: 4px !important;
    border: 1px solid rgb(33 103 172 / 61%) !important;
    color: #313131 !important;
    font-size: 14px !important;
    line-height: 23px !important;
    height: auto !important;
}

.profle-mndiv .border-btm {
    border-bottom: 1px solid rgb(112 112 112 / 90%);
    padding-bottom: 40px;
}

.ps-profileyr .servc-ps {
    border: 1px solid rgb(33 103 172 / 61%);
    display: block;
    margin-top: 20px;
    border-radius: 5px;
}

.ps-profileyr .servc-ps {
    border: 1px solid rgb(33 103 172 / 61%);
    position: relative;
    display: block;
    margin-top: 60px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.ps-profileyr .servc-ps .srvc-txt {
    position: absolute;
    top: -22px;
    left: 15px;
    background-color: #fff;
    padding: 8px 8px;
    color: #6E9CC9;
    font-size: 15px;
}

.ps-profileyr .servc-ps .srvc-list {
    list-style: none;
    padding: 30px 16px;
}

.ps-profileyr .servc-ps .srvc-list li a {
    background-color: #2167ACA6;
    padding: 6px 15px;
    margin: 10px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 15px;
    line-height: 13px;
    -webkit-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
    position: relative;
    text-align: center;
}

.column-services {
    margin-bottom: 35px;
    min-width: 100px;
}

.servc-updt .updt-prfile {
    text-align: center;
    display: block;
    background-color: #0071BC;
    border-radius: 30px;
    padding: 9px 32px;
    font-size: 20px;
    color: #FFFFFFFC;
    margin: 30px auto 0;
    border: none;
}

.updt-prfile:active {
    border: none !important;
    outline: none !important;
}

.ps-profileyr .updat-yor {
    font-size: 25px;
    color: #313131;
    line-height: 36px;
    font-weight: bold;
    margin: 40px 0 30px 0;
}

.quris-tpRight h4 i {
    margin-left: 5px;
}

.query-response-box {
    border-radius: 7px;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    float: left;
    background: transparent;
}

.my-queries-button {
    background-color: #0071bc !important;
    border: none !important;
}

.selected_user_img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.selected-user-span {
    padding-right: 10px;
}

.navbar-item-con {
    justify-content: end;
    width: 100%;
    align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.maxprofiledata {
    display: block;
    float: right;
    font-size: 12px;
    color: #6d93dc;
    margin-top: -16px;
}

.maxPageTitle {
    position: absolute;
    right: 8px;
    top: 15px;
    color: #0071bc;
    font-size: 12px;
}

.page-title-queries {
    position: relative;
}

.editor .ck-editor .ck-editor__main .ck-content {
    height: 100px;
}

.fixed-bottom {
    position: fixed;
    bottom: 0px;
}

.new-get-query-div {
    width: 100% !important;
}

.delete-icon-article-final {
    position: absolute;
    right: -77px;
    cursor: pointer;
    bottom: 4px;
    font-size: 18px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
    z-index: 1;
}

.article-edit-icon-svg {
    position: absolute;
    bottom: 4px;
    right: -50px;
}

.cancel-button-edit-time,
.article-cancel-edit {
    float: right;
    padding: 8px 16px;
    font-size: 17px;
    line-height: 20px;
    margin-right: 10px;
    background-color: transparent !important;
    color: #0071bc;
}

.cancel-button-edit-time:hover,
.article-cancel-edit:hover {
    background-color: transparent !important;
    color: #0071bc !important;
}

.cancel-button-edit-time:focus,
.article-cancel-edit:focus {
    color: #0071bc !important;
}

.published-date {
    font-style: italic;
}

.maxPostTitle {
    position: absolute;
    right: 8px;
    color: #0071bc;
    font-size: 14px;
    bottom: -26px;
}

.mmax-post-title {
    margin-bottom: 2.5rem;
}

.eye-anonymousName {
    position: absolute;
    right: 10px;
    top: 18px;
    font-size: 18px;
    cursor: pointer;
    background-color: #fff;
}

.anonymous-input-section {
    position: relative;
}

/* emoji css */
.emoji-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
}

.section-emoji-open {
    width: 50px;
    height: 50px;
    background-color: #0071bc;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.section-emoji-open svg {
    font-size: 25px;
    color: #fff;
}

.section-right-sidebar {
    width: 300px;
    background-color: #fff;
    height: 80%;
    display: flex;
    z-index: 1;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
    padding: 10px;
    flex-direction: column;
}

.sidebar-close-section {
    width: 50px;
    height: 50px;
    background-color: #0071bc;
    border-radius: 50%;
    position: fixed;
    top: 45%;
    right: 273px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 25px;
}

.images-sidebar {
    width: 55px;
    margin-bottom: 10px;
    cursor: pointer;
}

.emoji-main-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    flex-wrap: wrap;
}

.mood-heading-sidebar {
    font-size: 20px;
    color: #0071BC;
    font-weight: 800;
    text-align: center;
}

.emoji-text {
    margin-bottom: 0px;
}

.new-class-active {
    margin-right: 40px;
    color: #0071BC;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid #0071bc;
}

.dropdown-item {
    padding: 0;
}

.dropdown-item:active {
    background-color: transparent !important;
}

.new-dropdown-active {
    color: #fff;
    background-color: #0071BC;
    display: block;
    padding: 3px 10px;
    box-shadow: 0 0 2px 0 #0000007a;
    margin: 3px 0;
    font-size: 14px;
}

.new-dropdown-inactive {
    color: #000;
    display: block;
    padding: 3px 10px;
    box-shadow: 0 0 2px 0 #0000007a;
    margin: 3px 0;
    font-size: 14px;
}

.new-dropdown-active:hover {
    color: #fff;
}

.new-dropdown-inactive:hover {
    color: #000;
}

.mood-chart-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: aliceblue;
}

.mood-chart-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0px;
}

.mood-chart-percent-outer {
    width: 200px;
    background-color: #0071bcd6;
    height: 8px;
    margin-left: 10px;
    border-radius: 10px;
}

.mood-chart-percent-inner {
    background-color: orange;
    height: 100%;
    border-radius: 10px;
}

.mood-chart-heading {
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    width: 50%;
}

.mood-chart-button {
    position: absolute;
    right: 0;
    top: 95px;
    font-size: 14px;
}

.mood-chart-percent {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0;
    font-style: italic;
    margin-bottom: 0px;
    font-weight: 600;
}

.back-color-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF9226;
}

.articles-slider .slick-slider .slick-initialized {
    padding: 30px;
    margin-bottom: 10px;
}

.articles-slider {
    position: relative;
}

.articles-slider .slick-slider .slick-next,
.articles-slider .slick-slider .slick-prev {
    background: #0071bc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.slick-disabled {
    display: none !important;
}

.articles-slider .slick-slider .slick-prev:before {
    background-image: url('../public/images/back.png') !important;
    background-size: 20px 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
    opacity: 1;
}

.articles-slider .slick-slider .slick-next:before {
    background-image: url('../public/images/next.png') !important;
    background-size: 20px 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
    opacity: 1;
}

.spam-overlay-page {
    width: 25% !important;
}

.main-section-banner {
    width: 100%;
    display: block;
    float: left;
    overflow: hidden;
    height: 500px;
}

.banner-one-image {
    background-image: url(../src/images/ps-banner-1.png);
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    height: 100%;
}

.delete-icon-article {
    font-size: 18px;
    color: #fff;
}

.delete-icon-article-outer {
    background-color: #0071bc;
    border-radius: 15%;
    padding: 4px;
    cursor: pointer;
    height: 28px;
    width: 26px;
}

.article-btn-container {
    justify-content: space-around;
}

.delete-icon-query-outer {
    background-color: #0071bc;
    border-radius: 15%;
    padding: 2px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -3px;
    bottom: -31px;
    position: absolute;
    z-index: 1;

}

.banner-slider .slick-slider .slick-next,
.banner-slider .slick-slider .slick-prev {
    width: 50px;
    height: 50px;
    z-index: 1;
}

.banner-slider .slick-slider .slick-next {
    right: 20px;
}

.banner-slider .slick-slider .slick-prev {
    left: 20px;
}

.banner-slider .slick-slider .slick-prev:before {
    background-image: url('../public/images/bnr-prev.png') !important;
    background-size: 25px 25px;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    opacity: 1;
}

.banner-slider .slick-slider .slick-next:before {
    background-image: url('../public/images/bnr-next.png') !important;
    background-size: 25px 25px;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    opacity: 1;
}

.banner-slider .row {
    width: 100%;
    --bs-gutter-x: 0;
}

.banner-back-image {
    min-height: 425px;
    padding: 0;
    background-size: cover !important;
    background-position: center center !important;
    width: 100%;
    display: flex;
    align-items: center;
}

.banner-stress {
    font-size: 38px;
    font-weight: 600;
    color: #000;
}

.banner-consult {
    font-size: 53px;
    font-weight: 600;
    color: #1c92ff;
}

.banner-first-image {
    width: 100%;
    height: 98%;
    background: rgba(0, 0, 0, 0.12);
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
}

.text-upper-section-bnr {
    background: #dedede;
    width: 55%;
    display: flex;
    float: left;
    height: 300px;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 555;
    position: relative;
    opacity: .9;
}

.cntr-sction-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tutorial-heading {
    background: #494949;
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    font-size: 40px;
    font-weight: 700;
    color: #f8f8f8;
}

.tutorial-image {
    height: auto;
    border: 1px solid #00000040;
    width: 100%;
    object-fit: contain;
}

.tutorial-step {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    color: #F29918;
    padding: 5px 0px 5px 0px;
}

.tutorial-head-inner {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.tutorial-data {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    color: #000000;
    padding-top: 5px;
    margin-bottom: 0;
}

.tutorial-separator {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 40px 0px 50px 0px;
}

.tutorial-separator-page2 {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 20px 0px 20px 0px;
}

.tutorial-inner {
    padding: 60px 0px 30px 0px;
}

.tutorial-links {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    text-align: center;
    color: #646464;
    border: none;
    background: none;
}

.tutorial-links:hover {
    background-color: #494949b3;
    color: #fff;
}

.tutorial-links-section {
    position: sticky;
    top: 20px;
    height: 100vh;
}

.selected-tutorial {
    background-color: #494949;
    color: #fff;
}


.points-image-main-card {
    background-image: linear-gradient(to top, #cb9425, #dcab23, #eac320, #f6dc1f, #fff621);
    width: 15px;
    height: 15px;
    border-radius: 50%;

}

.coins-main-upper-section {
    display: flex;
    position: absolute;
    top: 10px;
    left: 30px;
    width: 50%;
    align-items: baseline;
    z-index: 1;
}

.coins-main-upper-section p {
    margin-left: 5px;
}

.gold-create-button,
.active-create-button {
    color: #fff;
    outline: none;
    border: none;
    padding: 6px 0px;
    border-radius: 15px;
    width: 48%;
    font-size: 13px;
    background: #f59127;
}

.active-create-button {
    background: #0071bc;
}

.gold-active-admin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 55px;
    width: 88%;
    z-index: 1;
}

.dropdown-link-icon {
    padding-right: 6px;
    font-size: 17px;
    color: #0071bc;
}

.image-dropdown-links .dropdown-menu {
    padding: 0;
    border: 0;
    border-radius: 0;
    min-width: 8rem;
}

.image-dropdown-links .dropdown-menu .dropdown-item {
    box-shadow: 0px 0px 2px 0px #0000007a;
    margin: 3px 0px;
    font-size: 14px;
}

.new-dropdown-active .dropdown-link-icon {
    color: #fff;

}

.tutorial-data span {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 5px;
}

.edit-article {
    background-color: #0071bced;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    width: 150px;
    position: relative;
    font-size: 16px;
    padding: 3px 10px;
    line-height: 25px;
    height: 32px;
    cursor: pointer;
}

.article-upload-icon {
    text-align: center;
    font-size: 7rem;
    color: #0071bc7d;
}

.input-file-article {
    position: absolute;
    left: 0;
    opacity: 0;
    height: 32px;
    width: 150px;
    font-size: 0;
    cursor: pointer;
}

.article-image-review {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 30px;
}

.art-img {
    height: 100%;
    width: 100%;
    padding: 3px;
    border-radius: 10px;
    object-fit: contain;
}

.article-img-container {
    margin: 7px;
    border: 3px dashed #a19696;
    height: 280px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.img-myarticle {
    background: #3f3f3f0a;
    border-radius: 10px;
    padding: 0;
}

.art-img-inner {
    position: relative;
}

.change-article-image {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.change-preview-article {
    background-color: #0071bc;
    border-radius: 50%;
    position: relative;
    padding: 7px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-preview-article img {

    height: 25px;
    width: 25px;

}

.login-error {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-error a {
    background: #003558;
    color: #fff !important;
    padding: 5px 60px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.5s linear;
}

.login-error a:hover {
    background: #0b4f7cf7;
}

.input-error-login {
    height: 45px;
    width: 100%;
    float: left;
    padding: 14px;
    border-radius: 4px;
    border: 1px solid rgb(33 103 172 / 61%);
    color: #313131;
    font-size: 14px;
    line-height: 23px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.input-error-login:focus-visible {
    outline: none;
}

.login-error-container {
    position: relative;
}

.login-error-container label {
    position: absolute;
    top: 5px;
    left: 23px;
    background: #fff;
    font-size: 15px;
    padding: 0px 5px;
    color: rgb(33 103 172 / 61%)
}

.login-error-container textarea {
    font-size: 14px;
    padding: 15px 10px;
    border: 1px solid rgb(33 103 172 / 61%);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.login-error-container textarea:focus {
    border: 1px solid rgb(33 103 172 / 61%) !important;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29) !important;
}

.member-others-sidebar li {
    width: 100%;
}

.no-search-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userNotFound {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    font-size: 18px;
    color: #0071bc;
}

.userNotFound p {
    font-family: 'Times New Roman', Times;
}

.footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-active {
    color: #0b3558;
    background: #fff;
    border: 1px solid rgb(188 228 255 / 85%);
    border-radius: 5px;
    padding: 3px 8px;
    font-size: 14px;
    transition: all 0.5s ease-in-out;
}

.footer-Inactive {
    color: rgb(188 228 255 / 85%);
    border: 1px solid rgb(188 228 255 / 85%);
    border-radius: 5px;
    padding: 3px 8px;
    font-size: 14px;
    transition: all 0.5s ease-in-out;
}

.footer-Inactive:hover {
    color: #0b3558;
    background: rgb(188 228 255 / 85%);
}

.delete-icon-admin-inner {
    background-color: #0071bc;
    border-radius: 15%;
    padding: 2px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -3px;
    bottom: -31px;
    position: absolute;
    z-index: 1;
}

.profile-public-show {
    display: flex;
    width: 100%;
    float: left;
    padding: 11px;
    border-radius: 4px;
    border: 1px solid rgb(33 103 172 / 61%);
    color: #313131;
    font-size: 15px;
    line-height: 26px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.profile-public-container {
    position: relative;
    margin: 15px 0px;
}

.profile-public-container span {
    color: #6E9CC9;
    line-height: 21px;
    position: absolute;
    top: -12px;
    left: 23px;
    background-color: #fff;
    padding: 0px 8px;
    z-index: 1;
    font-size: 15px;
}

.profile-public-show .form-check {
    padding-left: 15px;
}

.consultation-profile {
    margin-top: -5px;
}

.social-links-outer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-links-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    font-size: 21px;
    color: #ffffffe0;
    transition: all 0.5s ease-in-out;
}

.social-links-inner a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-links-inner a:hover {
    color: unset;
}

.social-links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.inner-footer {
    align-items: center;
    padding-bottom: 20px;
}

.logo-ps-footer {
    height: 40px;
    width: 135px;
}

.heplus-links-inner {
    width: 100%;
    text-align: left;
    line-height: 30px;
    color: #312e2e;
    font-size: 14px;
}

.heplus-links-inner a {
    display: flex;
    align-items: center;
    transition: transform 0.5s linear;
    width: fit-content;
}

.heplus-links-inner a:hover {
    color: #2171bc;
    font-weight: 600;
    transform: translateX(10px);

}

.help-us-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.followus-footer {
    padding: 0px 0px 6px 10px;
    font-size: 14px;
    color: #312e2e;
}

.social-follow-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.help-us-icon {
    font-size: 18px;
}

.followus-separator {
    height: 3px;
    background: #2171bc;
    width: 18%;
    margin: 0px 0px 10px 10px;
}

.social-media-color-icon {
    height: 25px;
    width: 25px;
    transition: transform .5s linear;
}

.social-media-color-icon:hover {
    transform: scale(1.2);
    transition: transform .5s linear;
}

.tab-row-set {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.how-it-works-2-cont {
    flex-grow: 1;
}

.tab-row-set-new {
    width: 100%;
    margin: 0 auto;
    display: flex;
}

.draft-query {
    float: right;
    padding: 8px 8px;
    font-size: 16px;
    line-height: 20px;
    margin-right: 10px;
    background-color: transparent !important;
    color: #0071bc !important;
}

.draft-query:hover {
    background-color: transparent !important;
    border-color: #0071bc;
    color: #0071bc !important;
}

.update-btn-flex {
    display: flex;
    justify-content: end;
    align-items: center;
}

.draft-query-separator {
    margin: 5px 10px 10px;
    height: 2px;
    background: #0940644a;
    position: relative;
}

.other-query-separator-new {
    margin: 30px 10px 10px;
    height: 2px;
    background: #0940644a;
}

.draftqueries {
    padding: 1px 15px;
    font-size: 16px;
    width: fit-content;
    color: #ff8500;
    font-weight: 600;
    margin-top: 25px;
}

.banner-readmore-modal .modal-dialog {
    max-width: 550px;
}

.banner-readmore-modal .modal-dialog .modal-content {
    border: 3px solid #0071bc;
    border-radius: 0;
}

.banner-readmore-modal .modal-dialog .modal-content .modal-body {
    padding: 2rem;
}

.banner-modal-heading {
    font-size: 27px !important;
    text-align: center;
    margin-bottom: 20px;
    color: #0071bc;
    font-family: 'BritannicBoldRegular';
}

.banner-data-read {
    font-size: 16px;
    text-align: center;
    line-height: 25px;
    font-family: 'Jost';
    color: #000000d6;
}

.banner-read-more {
    min-height: 425px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 555;
    background: #ffffffbf;
}

.banner-read-more h1 {
    font-size: 50px !important;
    text-align: center;
    color: #0071bc;
    font-family: 'BritannicBoldRegular';
    z-index: 555;
}

.banner-read-more p {
    font-size: 30px;
    font-family: 'Jost';
    text-align: center;
    padding: 0px 70px;
    color: #000;
    z-index: 555;
}

.banner-read-more button {
    background: #0071bc;
    border: none;
    padding: 7px 30px;
    border-radius: 20px;
    color: #fff;
    font-family: 'Jost';
    font-size: 20px;
    z-index: 555;
}

.fee-points-query {
    display: flex;
    background-image: linear-gradient(to top, #797979, #C5C5C5, #D4D4D4, #CECECE, #E5E5E5);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 3px;
}

.selected-user-fee {
    display: flex;
    justify-content: center;
    align-items: center;
}

.maxRequestLength {
    display: block;
    float: right;
    font-size: 12px;
    color: #0071bc;
    margin-top: -25px;
}

.request-active-blocked {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    z-index: 1;
}

.active-blocked-button {
    color: #fff;
    outline: none;
    border: none;
    padding: 6px 0px;
    border-radius: 15px;
    width: 90%;
    font-size: 14px;
    background-color: #0071bc;
}

.edit-icon-query-draft {
    position: absolute;
    right: -30px;
    cursor: pointer;
    bottom: 1px;
    font-size: 18px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
    z-index: 1;
}

.delete-icon-query-final-draft {
    position: absolute;
    right: -58px;
    cursor: pointer;
    bottom: 1px;
    font-size: 18px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
    z-index: 1;
}

.points-heading {
    border-left: 0px solid transparent;
    border-right: 70px solid transparent;
    border-top: 70px solid #0071bcc9;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.inner-points-heading {
    position: absolute;
    bottom: 33px;
    left: 5px;
    font-size: 14px;
    transform: rotate(-45deg);
    font-weight: 600;
    display: flex;
    align-items: center;
}

.selectUser {
    display: flex;
    justify-content: space-evenly;
}

.sidebarSelectgold {
    color: white;
    background-color: #0071Bc;
    font-size: 12px;
    padding: 2px 8px;
    border-bottom: 5px solid orange;
    border-left: 0;
    border-top: 5px solid #0071BB;
    border-right: 0;
    border-radius: 3px;
}

.sidebarSelectother {
    border: none;
    color: white;
    background-color: #0071Bc;
    font-size: 12px;
    padding: 7px 8px;
    border-radius: 3px;
}

.sidebarViewButton {
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 3px;
    background-color: transparent !important;
    color: #0071bc !important;
    border: 1px solid #0071bc;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile--now {
    background: white;
    color: black;
}

.calenderIcon {
    position: absolute;
    font-size: 50px;
    color: #0071BC;
    top: -5px;
    right: 8px;
    z-index: 1;
}

.highlighted-dates {
    color: white !important;
    border-radius: 33%;
    background-color: #137BC1 !important;
    padding: 7px 15px !important;
}

.calenderComponent {
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50px;
}

.calenderComponent .react-calendar__navigation {
    background: #137BC1;
    border-radius: 12px 12px 0 0;
    height: 59px !important;
    margin-bottom: 0 !important;
}

.calenderComponent .react-calendar__navigation button:hover {
    background-color: unset !important;
}

.calenderComponent .react-calendar__navigation button span {
    font-size: 15px;
    color: white;
}

.calenderComponent .react-calendar__viewContainer {
    font-size: 15px !important;
}

.calenderComponent .react-calendar__navigation .react-calendar__navigation__next-button,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__prev-button,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__prev2-button,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__next2-button {
    color: rgb(175, 174, 174);
}

.calenderComponent .react-calendar__navigation .react-calendar__navigation__next-button:hover,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__prev-button:hover,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__prev2-button:hover,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__next2-button:hover {
    color: white !important;
    background-color: unset !important;
}

.calenderComponent .react-calendar__navigation .react-calendar__navigation__next-button:enabled:focus,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__prev-button:enabled:focus,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__prev2-button:enabled:focus,
.calenderComponent .react-calendar__navigation .react-calendar__navigation__next2-button:enabled:focus {
    color: rgb(175, 174, 174);
    background-color: unset !important;
}

.calenderComponent .react-calendar__navigation .react-calendar__navigation__label:enabled:focus {
    background-color: unset !important;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays div abbr {
    text-decoration: none;
    color: #918E8E;
    cursor: default;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__weekdays {
    padding-top: 15px;
    padding-bottom: 10px;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view {
    padding: 0px 10px 10px 10px;
}

.react-calendar__month-view__days__day--weekend {
    color: unset !important;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days {
    row-gap: 10px;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days button abbr {
    font-size: 13px;
}



.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth abbr {
    color: #868686;
}

.calenderComponent .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth {
    background-color: unset !important;
}

.draft-badge {
    position: absolute;
    right: 45px;
    top: 13px;
    background: #0071bc;
    text-align: center;
    line-height: 20px;
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    padding: 0px 7px 0px 7px;
    border-radius: 10px;
}

.draft-badge-userquery {
    right: 36px;
    top: 14px;
}

.poll_input {
    display: flex;
    align-items: center;
    position: relative;
}

.poll_input .frm-inpt-inr {
    flex-grow: 1;
    width: auto;
    display: flex;
}

.removeOption {
    color: #fff;
    background-color: #e24d4d;
    border-color: #e24d4d;
    padding: 5px 16px;
    font-size: 27px;
    height: 100%;
    display: flex;
    align-items: end;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.removeOptionCross {
    color: #fff;
    background-color: #0071bc;
    border: 3px solid #0071bc;
    padding: 5px;
    height: 32px;
    width: 32px;
    line-height: 13px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
}

.removeOptionCross:hover {
    background-color: #0071bc;
    border-color: #0071bc;
    color: #fff;
}

.new-option-poll {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.removeOption:hover {
    background-color: #e24d4d;
    border-color: #e24d4d;
    color: #fff;
}

.removeOption:focus {
    box-shadow: none !important;
}

.polling-radio-btn {
    display: flex;
    align-items: center;
    margin: 3rem 0 1rem 0;
}

.polling-radio-btn .btn-radio {
    display: flex;
    align-items: center;
}

.polling-btn-create .updt-prfile {
    margin: 30px 0;
}

.polling-input-inner {
    border-radius: 4px 0px 0px 4px !important;
}

.polling-input-inner:focus {
    box-shadow: none !important;
}

.listpoll {
    flex: 1;
}

.listpoll-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 25px;
}

.ques-heading-poll {
    text-align: center;
}

.listpoll-container .card {
    height: 100%;
    background: linear-gradient(90deg, transparent, #0000ff1c);
}

.listpoll-container .card .list-group {
    flex-grow: 1;
}

.listpoll-container .card .card-body {
    text-align: center;
}

.listpoll-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.listpoll-btn-wrapper .blue-poll {
    border-radius: 30px;
    width: 40%;
    padding: 5px;
    margin: 11px;
}

.listpoll-btn-wrapper .orange-poll {
    border-radius: 30px;
    width: 40%;
    padding: 5px;
    margin: 11px;
    background-color: #FF9226 !important;
    border-color: #FF9226 !important;
}

.poll-chart-button {
    position: absolute;
    left: 0;
    top: 141px;
    font-size: 14px;
    background-color: #FF9226 !important;
    border-color: #FF9226 !important;
}

.poll-ana-chart-button {
    position: absolute;
    left: 0;
    top: 95px;
    font-size: 15px;
    background-color: #0071bc !important;
    border-color: #0071bc !important;
}

.poll-chart-button:hover {
    background-color: #FF9226 !important;
    border-color: #FF9226 !important;
    color: #fff;
}

.box-checked-poll {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 12px;
}

.box-checked-poll input {
    display: block;
    background-color: #ECF0F3 !important;
    width: 1.3rem !important;
    height: 1.3rem !important;
    border: 1px solid #0003;
    box-shadow: none !important;
    appearance: none;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.box-checked-poll .inputcheckbox {
    border-radius: 20% !important;
}

.box-checked-poll .inputradio {
    border-radius: 50% !important;
}

.box-checked-poll input:checked {
    background-color: #FF9226 !important;
    border: none;
    background-image: url("../public/images/check.png");
    background-size: 10px 12px;
}

.dash-poll {
    padding: 2rem 4rem;
    background: #eee;
}

.dash-poll-head {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Jost';
    margin-bottom: 20px;
    color: #0071bc;
}

.dash-poll-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Jost';
    cursor: pointer;
    padding: 15px;
}

.dash-poll-inner {
    max-width: 400px;
    background: #fff;
    border-radius: 4px;
    margin: 5px 0px 20px 0px;
    transition: 0.5s ease;
    cursor: pointer;
}

.dash-poll-inner-border {
    max-width: 400px;
    background: #fff;
    border-radius: 4px;
    margin: 5px 0px 20px 0px;
    border: 3px solid #FF9226;
    transform: translateX(20px);
    transition: transform 0.5s ease;
    box-shadow: 0px 0px 3px 1px #b58a174d;
    cursor: pointer;
}

.dash-poll-btn {
    padding: 7px 25px;
    margin-top: 10px;

}

.dash-poll-img {
    background: #0071bc;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.dash-modal-poll .modal-dialog {
    max-width: 300px;
}

.dash_multiple_opt {
    color: #FF9226;
    font-weight: 600;
}

.option-poll-chart {
    width: 100%;
    display: flex;
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 10px 0px;
}

.poll-analytic-heading {
    color: #0071bc;
    font-size: 24px;
    font-weight: 600;
    padding: 15px 0 0;
    font-family: revert;
}

.poll-chart-inner {
    flex-direction: column;
    align-items: flex-start;
}

.poll-analytics-cont {
    width: 100%;
    display: flex;
    align-items: center;
}

.poll-chart-opt {
    font-weight: 600;
}

.maxQuestionTitle {
    position: absolute;
    right: 8px;
    bottom: -21px;
    color: #0071bc;
    font-size: 12px;
}

.poll-chart-not-head {
    width: 100%;
    padding: 50px 0px;
    font-size: 24px;
}

.query-reply-box .ck-editor {
    width: 100% !important;
}

.startTime-profile {
    display: flex;
    align-items: center;
}

.removeOption-profile {
    color: #fff;
    background-color: #e24d4d;
    border-color: #e24d4d;
    padding: 5px 16px;
    font-size: 27px;
    display: flex;
    align-items: end;
    border-radius: 4px;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.removeOption-profile:hover {
    color: #fff;
    background-color: #e24d4d;
    border-color: #e24d4d;
}

.profile-border-btm {
    border-bottom: 1px solid rgb(112 112 112 / 90%);
    padding-bottom: 60px;
}

.selectedWeekDay {
    border: 1px solid #6E9CC9;
    padding: 5px 10px;
    color: #ffffff;
    background-color: #6E9CC9;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 80px;
}

.selectedWeekDay-profile-modal {
    border: 1px solid #003558;
    padding: 5px 10px;
    color: #ffffff;
    background-color: #003558;
    border-radius: 15px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 145px;
}


.unselectedWeekDay {
    border: 1px solid #6E9CC9;
    padding: 5px 10px;
    color: #6E9CC9;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 80px;
}

.days-available {
    color: #6E9CC9;
    margin: 30px 15px;
    height: 1px;
    position: relative;
    background: #6E9CC9;
    width: 95%;
}
.days-available-profile-modal{
    color: #003558;
    margin: 30px 0px;
    height: 1px;
    position: relative;
    background: #003558;
    width: 95%;
    font-size: 14px;
}
.days-available-profile-modal span {
    color: #003558;
    padding: 0px 10px;
    position: absolute;
    background: #fff;
    top: -11px;
    left: 10px;
}


.days-available span {
    color: #6E9CC9;
    padding: 0px 10px;
    position: absolute;
    background: #fff;
    top: -11px;
}

.removeOptionCross-profile {
    color: #fff;
    background-color: #313131;
    border: 3px solid #313131;
    padding: 4px;
    height: 28px;
    width: 28px;
    line-height: 13px;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}

.removeOptionCross-profile:hover {
    color: #fff;
    background-color: #313131;
    border: 3px solid #313131;
}

.profile-timepicker {
    box-shadow: unset !important;
}

.profile-timepicker .react-datepicker-wrapper {
    width: 100%;
}

.profile-timepicker .rmdp-container {
    width: 100%;
}

.profile-timepicker .rmdp-container .rmdp-input {
    margin: 0 !important;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.29)
}

.profile-timepicker .rmdp-container .rmdp-wrapper .rmdp-time-picker input {
    width: 100% !important;
    float: left !important;
    padding: 0 !important;
    border-radius: 4px !important;
    border: none !important;
    color: #313131 !important;
    font-size: 12px !important;
    line-height: 23px !important;
    height: auto !important;
}

.profile-timepicker .rmdp-container .rmdp-wrapper .rmdp-time-picker {
    width: 255px !important;
    min-width: 255px !important;
}

.edit-icon-query-response {
    position: absolute;
    right: -27px;
    cursor: pointer;
    bottom: 5px;
    font-size: 16px;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 10%;
    z-index: 1;
}

.selectedWeekDay:disabled {
    background-color: #6e9bc952;
    border-color: #6e9bc952;
}

.unselectedWeekDay:disabled {
    background-color: #6e9bc952;
    border-color: #6e9bc952;
}




/* ------------Booking Slots--------- */
.booking-slots-container {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 40px;
}

.booking_slots_table {
    width: 100%;
    display: block;
    height: 50vh;
    overflow-y: scroll;
    /* width: 423px; */
}

.booking_slots_table thead {
    position: relative;
    width: 100%;
}

.booking_slots_thead {
    background: #0071bc;
    color: #fff;
    position: sticky;
    top: 0;
}

.booking_slots_table td {
    padding: 10px 20px;
}

.booking_slots_tr {
    border-bottom: 1px solid #0071bc54;
}

.booking_slots_btn {
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #0071bc;
    color: #0071bc;
    width: 80px;
}

.booking_slots_btn1 {
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 14px;
    background: #0071bc;
    border: 1px solid #0071bc;
    color: #fff;
    width: 80px;
}

.booking_slots_btn:disabled {
    background-color: #0071bc85;
    color: #fff;
    border: 1px solid #0071bc85;
}

.time_slots_btn {
    padding: 8px 11px;
    border-radius: 5px;
    font-size: 14px;
    background: #0071bc;
    border: 1px solid #0071bc;
    color: #fff;
    width: 150px;
    margin: 10px 20px;
    cursor: pointer;
}

.booking-container {
    flex-grow: 1;
}

.slots-booking-head {
    display: flex;
    flex-direction: column;
}

.booking_slot_days_td {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.booking_slot_days {
    background: #eee;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 13px;
}

.booking_slot_days-2 {
    background: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border: 1px solid #0071bc;
    color: #0071BC;
}

.booking_slot_days-3 {
    background: #0071bc;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border: 1px solid #0071bc;
    color: #fff;
}

.selected_booking_slot_days {
    background: #0071BC;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border: 1px solid #0071bc;
    color: #fff;
}

/*   TimePicker CSS   */
.profile-timepicker .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none;
}

.profile-timepicker .react-datepicker__time-container,
.profile-timepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
}

.profile-timepicker .react-datepicker-popper[data-placement^=bottom] {
    padding-top: 5px;
    width: 100%;
    z-index: 9 !important;
}

.profile-timepicker .react-datepicker-popper[data-placement^=top] {
    padding-bottom: 10px;
    width: 100%;
    z-index: 9 !important;

}

.profile-timepicker .react-datepicker-popper .react-datepicker--time-only {
    width: 50%;
}

.profile-timepicker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.profile-timepicker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.profile-timepicker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.profile-timepicker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    left: -50px;
}

.profile-timepicker .react-datepicker-time__header {
    color: #6E9CC9;
    padding: 0px 10px;
}

.profile-timepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #0071bc;
    color: white;
    font-weight: 400;
}

.profile-timepicker .react-datepicker-wrapper .booking-time-slot:focus-visible {
    border: 2px solid rgb(33 103 172 / 61%) !important;
    outline: none;
}

.time-confirm-modal {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.day-confirm-modal {
    display: block;
    text-align: center;
    font-size: 17px;
    padding: 10px;
    color: #616060;
    font-weight: 500;
    text-transform: uppercase;
}

.day-confirm-modal-details {
    display: block;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: #616060;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.calenderIcon-booking {
    font-size: 55px;
    color: #0071BC;
    display: block;
    text-align: center;
    width: 100%;
    margin: 20px 0px;
}

.confirmBooking-time .modal-dialog {
    max-width: 400px !important;
}

.booking-button-end {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.already-booked-modal {
    opacity: 0.9 !important;
}
.booking-table{
    max-width: 700px !important;
    width: 700px !important;
}
/* Media Quries */
@media only screen and (min-width:320px) and (max-width: 767px) {
    .banner-back-image {
        min-height: 200px !important;
    }

    .new-width-set-discussion {
        order: 3;
    }

    .modl-PS .mdl-left {
        width: 100%;
        display: block;
        margin-right: 0px;

    }

    .main-image-modal {
        margin: 0 auto;
        display: flex;
        margin-bottom: 15px;
        height: 60px;
        width: 60px;
    }

    .modl-PS .mdl-text-bx h2 {
        font-size: 17px;
    }

    .modl-PS .mdl-text-bx p {
        font-size: 13px;
    }

    .quris-tpRight h4 i {
        margin-left: 3px !important;
    }

    .quris-tpRight img {
        margin-right: 5px;
    }

    .set-in-mob {
        width: 47% !important;

    }

    .infinitescroll-dashboard {
        column-gap: 15px;
    }

    .bnr-input .input-type {
        width: 100%;
    }

    .bnr-input .bnr-inputIcone {
        width: 100%;
    }

    .header-bg {
        height: 73px;
    }

    .header-sec .hdr-logo .logo-ps img {
        width: 80px;
    }

    .bnr-input .bnr-inputIcone {
        padding: 20px;
    }

    .bnr-input .input-type {
        padding: 14px 40px 16px 18px;
        height: 40px;
        font-size: 12px;
    }

    .bnr-input .bnr-inputIcone .cls-icon {
        top: 32px;
        font-size: 18px;
        right: 35px;
    }

    .bnr-sec .bnr-text h4 {
        font-size: 16px;
        line-height: 22px;
    }

    .bnr-sec .bnr-text h1 {
        font-size: 20px;
        line-height: 15px;
    }

    .bnr-sec {
        margin-top: 35px;
    }

    .header-sec .hdr-btn .btn-log {
        margin-top: 7px;
        font-size: 16px;
    }

    .header-sec .hdr-logo .logo-ps img {
        margin-top: 12px;
    }

    .dashbord-strt .dashbord-box h1,
    .dashbord-strt .admin-dashbord-box h1 {
        font-size: 14px;
    }

    .dashbord-strt .dashbord-box p,
    .dashbord-strt .admin-dashbord-box p {
        font-size: 10px;
    }

    .dashbord-strt .dashbord-box {
        padding: 9px 2px 5px;
        height: 260px;
    }

    .dashbord-strt .dashbord-box .btn-qstn,
    .dashbord-strt .admin-dashbord-box .btn-qstn {
        padding: 4px 0px;
        font-size: 9px;

    }

    .dashboard-resp-row {
        display: flex;
        justify-content: center;
    }

    .padding-20-header {
        padding: 10px 20px;
    }

    .ps-footer p {
        font-size: 14px;
        padding: 10px;
    }

    .ps-footer {
        padding: 0;
    }

    .dashbord-strt {
        padding: 70px 0px;
    }

    .profile-info-data-dashboard {
        padding: 0px;
    }

    .modl-PS .ulmodal .ul-list li a {
        width: 100% !important;
        font-size: 11px !important;
        padding: 4px !important;
    }

    .modl-PS .modal-header .btn-close {
        padding: 8px !important;
    }

    .pd-on-mob {
        padding: 0px 15px;
    }

    .search-input-sidebar {
        width: 100%;
    }

    .quries-clrfix .slct-name div {
        display: grid;
        width: 98%;
        grid-template-columns: auto auto;
        margin: 0px 2px;
    }

    .quries-clrfix .slct-name div li a {
        width: 100%;
        margin-bottom: 10px;
        padding: 5px 2px;
        font-size: 12px;
    }

    .users-add-preview {
        right: -3px;
        height: 20px;
        width: 20px;
    }

    .form-check-label {
        font-size: 14px;
    }

    .sidebar-ps {
        height: 75vh;
        width: 100%;
    }

    .ps-name-box {
        margin-top: 15px;
        padding: 8px;
    }

    .quries-clrfix .align-itm {
        margin-bottom: 7px;
    }

    .quries-clrfix .btn-submit {
        float: left;
        font-size: 16px;
        padding: 5px 16px;
        margin-right: 0;
    }

    .ps-name-box .title-text.title-text-right {
        margin: 0;
    }

    .ps-name-box .todiv-ps {
        float: left;
        width: 83%;
    }

    .modl-PS .mdl-text-bx {
        width: 100%;
        text-align: center;
    }

    .eat-ps .eatps-box p {
        width: 100%;
        font-size: 18px;
    }

    .eat-ps .eatps-box h1 {
        font-size: 30px;
    }

    .eat-ps .eatTop {
        float: left;
    }

    /* Your-Profile page css  */

    .profle-mndiv {
        width: 100% !important;
        padding: 34px 10px !important;
    }


    .input-file-profile {
        width: 85px;
    }

    .servc-updt .updt-prfile {
        padding: 10px 52px;
        font-size: 20px;
    }

    .ps-profileyr .updat-yor {
        margin: 22px 0 28px 0 !important;
        font-size: 20px !important;
    }

    .quries-clrfix .wt-clrfix {
        font-size: 14px;
        line-height: 16px;
        padding: 11px 16px;
    }

    .ps-profileyr .servc-ps .srvc-list {
        display: inline-block;
        padding: 0px 10px;
    }

    .input-services-services {
        width: 92%;
        margin: 10px auto;
        align-items: flex-start;
    }

    .input-services-services input:focus {
        border: none !important;
    }

    .profle-mndiv .border-btm {
        padding-bottom: 20px;
    }

    .ps-profileyr .servc-ps {
        margin-top: 50px;
    }

    .ps-profileyr .servc-ps .srvc-list li a {
        float: left;
        margin: 10px 0;
    }

    .ps-profileyr .servc-ps .srvc-list {
        padding: 26px 0 4px 25px;
    }

    .ps-profileyr {
        padding: 12px;
    }

    .quris-tpRight h4 {
        font-size: 14px;
    }

    .quris-tpRight span {
        font-size: 14px;
        line-height: 4px;
    }

    .btn-radio-box {
        width: 50% !important;
        float: left;
    }

    .bnr-input {
        top: 235px;
    }

    .btn-log {
        display: flex;
        justify-content: end;
    }

    .input-services-services input {
        border: 1px solid black;
    }

    .srvc-list {
        width: 100%;
    }

    .srvc-list .row {
        display: grid;
        grid-template-columns: auto auto;
        width: 100%;
    }


    .get-position-set {
        order: 1;
        width: 100%;
    }

    .new-width-set-tab {
        order: 3;
    }

    .cross-icon-service-queries {
        width: 9px;
        margin-top: -2px;
    }

    .member-sidebar-resp {
        order: 2;
        width: 100% !important;
        gap: 10px;
        position: relative;
        right: 0px;
    }

    .members-tab-gold li .nav-link {
        width: 100%;
    }

    .selected_user_img {
        height: 25px;
        width: 25px;
    }

    .ps-name-box .title-text {
        width: 100%;
        float: none;
    }

    .ps-name-box .title-text p {
        font-size: 13px;
        text-align: left;
        margin-bottom: 2px;

    }

    .ps-name-box .title-text h1 {
        font-size: 14px;
        text-align: left;
    }

    .sidebar-ps .siderbar-box p {
        margin-bottom: 1rem;
    }

    .ps-name-box .input-grp-fil .frm-cntrol {
        font-size: 12px;
        height: 35px;
    }

    .ps-name-box .input-grp-fil .input-group-text {
        padding: 11px 6px;
        font-size: 13px;
        height: 35px;
    }

    .button-spam {
        font-size: 11px;
        padding: 2px 2px;
        bottom: -88px;
        right: 51px;
    }

    .respo-button-mob-spam {
        background: transparent;
        border: 1px solid #000;
        border-radius: 5px;
        bottom: -50px;
        color: #0071bc;
        font-size: 11px;
        padding: 2px;
        position: absolute;
        right: 10px;
    }

    .user-img-queries-sidebar {
        height: 50px;
        width: 50px;
    }

    .sidebar-ps .siderbar-box h2 {
        font-size: 16px;
    }

    .image-main-cards-dshbord {
        width: 50px;
        height: 50px;
        margin-bottom: 0px;
    }

    .navbar-item-con {
        display: contents;
        width: auto;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        padding: 0;
    }

    .dropdown-menu {
        min-width: 6rem;
    }

    .ps-profileyr .profle-mndiv .prfl-img {
        height: 180px !important;
        width: 180px !important;
    }

    .frm-inpt-profle .frm-inpt-inr input {
        height: 44px !important;
        font-size: 14px !important;
        padding: 0px 20px !important;
    }

    .frm-inpt-profle .frm-inpt-inr {
        margin: 12px 0 !important;
    }

    .column-services {
        margin-bottom: 15px !important;
    }

    .ps-profileyr .servc-ps .srvc-list li a {
        padding: 7px 14px !important;
        margin: 0px !important;
        font-size: 13px !important;
    }

    .input-services-services input {
        height: 40px;
    }

    .ps-profileyr .servc-ps {
        margin-top: 40px !important;
    }

    .srvc-list {
        margin-bottom: 0px;
    }

    .servc-updt .updt-prfile {
        padding: 8px 16px !important;
        font-size: 16px !important;
        width: 170px !important;
    }

    .logo-section-header img {
        width: 80%;
    }

    .dropdown-image-icon {
        height: 85%;
        width: 85%;
    }

    .dropdown-menu[data-bs-popper] {
        top: 80%;
    }

    .dropdown-item {
        text-align: left;
        font-size: 14px;
    }

    .dropdown-menu[data-bs-popper] {
        left: -54px !important;
    }

    .image-dropdown-links .dropdown-menu {
        min-width: 8rem;
    }

    .query-response-box {
        margin-bottom: 25px !important;
        position: relative;
    }

    .query-reply-box {
        top: 10px;
    }

    .new-like-comment {
        float: left;
        padding-top: 10px;
        width: 100%;
    }

    .float-design {
        float: left !important;
    }

    .new-resp-login {
        font-size: 12px !important;
    }

    .heading-inner-modal {
        font-size: 16px !important;
        margin-bottom: 25px !important;
    }

    .main-section-animation-arrow {
        width: 42px !important;
        height: 42px !important;
        margin-bottom: 25px !important;
    }

    .arrow-down {
        height: 4px !important;
        transform-origin: 0% 100% !important;
        width: 18px !important;
    }

    .ps-profileyr .profle-mndiv .edit-prfl {
        padding: 5px 0px !important;
        font-size: 16px !important;
        width: 75px !important;
    }

    .modl-PS .ulmodal .ul-list {
        display: grid;
        grid-template-columns: auto auto;
    }

    .sidebar-ps .gold-mmbr {
        font-size: 17px;
    }

    .userquery-tab-container ul {
        width: 100%;
    }

    .userquery-tab-container ul li {
        width: 50%;
    }

    .userquery-tab-container ul li .nav-link {
        font-size: 12px;
        height: 41px;
        margin-right: 2px;
        margin-bottom: 8px;
    }

    .userquery-tab-container ul li {
        margin-right: 0px;
    }

    .bredcrum-ps {
        display: none;
    }

    .quries-clrfix {
        padding-top: 30px;
    }

    .no-query-section-two {
        width: 274px;
        padding: 25px;
    }

    .no-query-section-two h2 {
        font-size: 21px;
    }

    .no-query-section-two p {
        font-size: 13px;
    }

    .no-query-link {
        padding: 8px 25px;
        font-size: 13px;
    }

    .gold-heading {
        border-left: 50px solid transparent;
        border-top: 50px solid #FF9226;
    }

    .inner-gold-heading {
        font-size: 11px;
        bottom: 25px;
    }

    .new-query-tooltip .tooltip-inner {
        max-width: 140px;
        padding: 1px 3px;
    }

    .participants-inner-section {
        margin: 3px 0px;
        padding: 2px 3px;
    }

    .participants-image {
        width: 28px;
        height: 28px;
    }

    .information_section_participants {
        margin-left: 6px;
    }

    .information_section_participants p {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
    }

    .outer-section-participants h3 {
        font-size: 13px;
    }

    .outer-section-participants h3 div {
        width: 18px;
        height: 18px;
    }

    .outer-section-participants h3 div span {
        font-size: 12px;
    }

    .delete-icon-modal-query {
        position: absolute;
        right: 6px;
        cursor: pointer;
        bottom: -27px;
    }

    .why-spam-heading {
        font-size: 15px;

    }

    .spam-response-box {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .spam_submit_button {
        padding: 5px 6px;
        font-size: 12px;
    }

    .button-add-service {
        margin-left: 0;
        padding: 0;
        border-radius: 0px;
        height: 40px;
        font-size: 13px;
        width: 80px;
    }

    .delete-modal-heading {
        font-size: 15px !important;
    }

    .button-delete-modal,
    .button-delete-modal-No {
        padding: 1px 10px;
        margin-left: 10px;
        font-size: 13px;
        height: 33px;
    }

    .anonymous-img-queries {
        bottom: -54px;
        top: unset;
        width: 17px;
        right: 26px;
    }

    .new-private-img-queries {
        right: 51px;
        height: 18px;
        bottom: -54px;
    }

    .second-anonymous {
        right: 26px;
    }

    .maxPageTitle,
    .maxPostTitle {
        right: 12px !important;
        top: 31px !important;
        font-size: 11px !important;
        background: white;
        padding: 0px 3px;
    }

    .mmax-post-title {
        margin-bottom: 15px;
    }

    .icon-qresp-respon {
        font-size: 17px !important;
        margin-right: 3px;
        margin-top: -3px;
        cursor: pointer;
        position: absolute !important;
        left: 0px !important;
        bottom: -51px !important;
    }

    .like-qresp-count-respon {
        position: absolute !important;
        left: 21px !important;
        bottom: -54px !important;
    }

    .delete-icon-query-final {
        right: 0px;
        bottom: -53px;
        font-size: 15px;
        box-shadow: 0px 0px 2px 1px #000;
    }

    .auto-height-private {
        height: 70vh
    }

    .maxprofiledata {
        margin-top: -10px;
    }

    .outer-section-participants {
        margin-top: 0px;
        margin-bottom: 17px;
    }

    .mobile-sidebar-not-view {
        display: none;
    }

    .mobile-sidebar-view {
        display: block;
        width: 100%;
    }

    .discussion-link-header {
        order: 2;
        display: none;
    }

    .discussion-link-view {
        display: block !important;
    }

    .new-class-active {
        display: none;
    }

    .new-get-query-div {
        overflow: hidden;
    }

    .copy-button-modal-section a {
        font-size: 10px;
        padding: 6px 8px;
    }

    .copy-button-modal-section {
        right: 5px;
    }

    .responsive-mobile-modal {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .contact-button-modal {
        font-size: 11px;
        padding: 6px 8px;
    }

    .article-radio-btn {
        width: 50% !important;
    }

    .publish-article {
        font-size: 15px;
    }

    .view-article-box {
        padding: 7px
    }

    .article-ps .article-box {
        height: 335px;
        margin: 0 0 5px 0;
    }

    .article-ps .article-box .grl-musicn {
        height: 110px;
    }

    .article-ps .article-box .pding-20 {
        padding: 10px 6px
    }

    .article-ps .article-box h2 {
        font-size: 13px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .article-ps .article-box p {
        font-size: 12px;
        line-height: 16px;
    }

    .article-ps .article-box .article-bx-inr {
        padding: 0px 6px;
        bottom: 10px;
    }

    .flex-center-add {
        display: block;
    }

    .article-ps .article-box .readmore {
        margin-top: 5px;
        font-size: 11px;
        width: 75%;
    }

    .article-ps .article-box .article-bx-inr h5 {
        font-size: 12px;
        padding: 0px 5px;
        margin-bottom: 0px;
    }

    .published-by-article {
        width: unset;
    }

    .article-ps .article-box .article-bx-inr img {
        width: 25px;
        height: 25px;
    }

    .eat-ps .eatps-box .adequat-img {
        height: 260px;
        margin-top: 0px;
        width: 100%;
        margin-bottom: 0px;
    }

    .article-view-title {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .article-content-view {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    .article-content-view p {
        font-size: 14px !important;
        margin-bottom: 0px !important;
    }

    .userquery-tab-container {
        padding: 0;
    }

    .tab-row-set,
    .tab-row-set-new {
        width: 100%;
        margin: 0 auto;
    }

    .article-view-like {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .article-view-like .new-like-comment {
        display: flex;
    }

    .eat-ps .eatTop {
        order: 1;
        font-size: 14px;
    }

    .new-name-box {
        padding: 10px;

    }

    .new-name-box .query-response-box {
        margin-bottom: 10px !important;
        position: relative;
    }

    .image-new-cards-dshbord {
        width: 40px;
        height: 40px;
    }

    .new-name-box .title-text h1 {
        font-size: 14px;
        line-height: 16px;
    }

    .new-name-box .title-text p {
        font-size: 13px;
        line-height: 10px;
    }

    .new-name-box .input-grp-fil .input-group-text {
        padding: 0px 8px;
        font-size: 15px;
    }

    .edit-icon-query {
        right: 0px;
        bottom: -79px;
        font-size: 16px;
    }

    .image-user-article {
        width: 40px;
        height: 40px;
    }

    .new-image-article-view {
        font-size: 13px;
    }

    .delete-icon-article-final {
        right: 10px !important;
        bottom: -25px !important;
        font-size: 15px !important;
    }

    .mobile-ps-article {
        padding-bottom: 30px;
    }

    .article-underline-txt {
        position: absolute;
        bottom: -56px;
        right: 8px;
    }

    .cancel-button-edit-time {
        padding: 5px 19px !important;
        font-size: 16px !important;
    }

    .article-cancel-edit {
        padding: 5px 19px !important;
        font-size: 16px !important;
        margin-left: 10px;
    }

    .update-btn-flex {
        display: flex;
        justify-content: center;
    }

    .new-btn-full-width {
        width: 100% !important;
    }


    /* emoji part */
    .section-emoji-open {
        width: 35px !important;
        height: 35px !important;
    }

    .section-emoji-open svg {
        font-size: 22px !important;
    }

    .mood-heading-sidebar {
        font-size: 17px !important;
    }

    .images-sidebar {
        width: 40px !important;
    }

    .section-right-sidebar {
        width: 250px !important;
        height: 425px !important;
    }

    .sidebar-close-section {
        width: 35px !important;
        height: 35px !important;
        right: 232px !important;
        top: 50% !important;
    }

    .new-queries-clrfix {
        height: 100%;

    }

    .article-edit-icon-svg {
        position: absolute;
        bottom: -26px !important;
        right: 35px !important;
    }

    .eye-anonymousName {
        top: 14px !important;
        font-size: 16px !important;
    }

    .slider-btn {
        display: none;
    }

    .articles-slider .slick-slider .slick-dots .slick-active .ft-slick-dots-custom {
        height: 7px;
        width: 25px;
        background: #0071bc;
        border-radius: 5px;
        transition: all 1s linear;
    }

    .articles-slider .slick-slider .slick-dots .ft-slick-dots-custom {
        height: 7px;
        width: 25px;
        background: #6c757d;
        border-radius: 5px;
    }

    .articles-slider .slick-slider .slick-dots li {
        width: 25px;
        height: 10px;
    }

    .articles-slider .slick-slider .slick-dots {
        position: unset;
    }

    .articles-slider .slick-slider {
        padding-top: 25px;
        padding-left: 0;
    }

    .mood-chart-button {
        right: 6px !important;
        top: 75px !important;
        font-size: 11px;
        padding: 1px 5px;
    }

    .mood-chart-inner div img {
        width: 20px !important;
    }

    .mood-chart-inner {
        margin: 11px 0px;
        width: 70%;
        padding: 0;
    }

    .mood-chart-outer {
        padding-bottom: 5px;
    }

    .mood-chart-percent-outer {
        width: 110px !important;
    }

    .back-color-chart {
        display: block !important;
    }

    .mood-chart-outer {
        width: 240px !important;
    }

    .mood-chart-percent {
        width: 20%;
    }

    .mood-chart-outer .mood-chart-container {
        width: 100%;
        padding: 10px 0px;
    }

    .mood-chart-outer .mood-chart-container .row {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
    }


    .mood-chart-heading {
        margin-bottom: 0 !important;
        width: 100% !important;
        font-size: 16px;
        padding: 10px;
    }

    .delete-icon-article-outer {
        height: 25px !important;
        width: 23px !important;
        margin-top: 5px;
    }

    .delete-icon-article {
        font-size: 16px !important;
    }

    .banner-image {
        height: 220px !important;
    }

    .banner-slider .slick-slider .slick-dots {
        bottom: -3px;
    }

    .banner-slider .slick-slider .slick-dots .slick-active .banner-slick-dots-custom {
        height: 7px;
        width: 25px;
        background: #0071bc;
        border-radius: 5px;
        transition: all 1s linear;
    }

    .banner-slider .slick-slider .slick-dots .banner-slick-dots-custom {
        height: 6px;
        width: 23px;
        background: #ffffff94;
        border-radius: 5px;
    }

    .text-upper-section-bnr {
        background: unset !important;
        width: 100% !important;
        height: 200px !important;
    }

    .cntr-sction-banner {
        background: #dedede;
        padding: 15px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .banner-stress {
        font-size: 14px !important;
    }

    .banner-consult {
        font-size: 20px !important;
    }

    .button-spam-discussion {
        font-size: 11px;
        padding: 2px 2px;
        right: 51px;
    }

    .add-bottom-position {
        bottom: -88px;
        right: 10px;
    }

    .delete-icon-query-outer {
        right: 50px !important;
        bottom: -55px !important;
        height: 20px;
        width: 20px;
    }

    .ps-name-box .title-text .undrline-txt {
        font-size: 12px;
    }

    .dropdown-link-icon {
        display: none;
    }

    .tutorial-heading {
        padding: 10px 0px;
        font-size: 18px;
    }

    .tutorial-inner {
        padding: 30px 0px 0px 0px;
    }

    .tutorial-step {
        font-size: 20px;
        line-height: 8px;
    }

    .tutorial-head-inner {
        font-size: 14px;
        line-height: 20px;
    }

    .tutorial-data {
        font-size: 14px;
        line-height: 23px;
    }

    .tutorial-data span {
        font-size: 14px;
    }

    .tutorial-image {
        height: auto;
    }

    .tutorial-data-column {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .tutorial-step-column {
        display: none;
    }

    .tutorial-container {
        padding: 0px 5px
    }

    .tutorial-conainer-inner {
        display: flex;
        justify-content: center;
    }

    .tutorial-conainer-inner .row .col-md-4 {
        width: 50%;
        padding-left: 2px;
        padding-right: 2px;
    }

    .tutorial-separator {
        margin: 25px 0px 35px 0px;
    }

    .new-dropdown-inactive,
    .new-dropdown-active {
        padding: 4px 8px;
    }

    .img-article-outer {
        overflow-x: hidden;
        padding: 0px 5px;
    }

    .article-img-container {
        height: 175px;
    }

    .article-upload-icon {
        font-size: 5rem;
    }

    .edit-article {
        width: auto;
        font-size: 14px;
        padding: 2px 9px;
    }

    .footer-active,
    .footer-Inactive {
        display: none;
    }

    .mobile-ps-name {
        margin-top: 30px;
    }

    .spam-overlay-page {
        width: 32% !important;
    }

    .gold-active-admin {
        display: block;
        bottom: 36px;
    }

    .gold-create-button,
    .active-create-button {
        padding: 4px 0px;
        width: 100%;
        font-size: 9px;
        margin-top: 4px;
    }

    .admin-dashbord-box {
        height: 315px;
        padding: 30px 0px 0px;
    }

    .points-image-main-card {
        width: 10px;
        height: 10px;
    }

    .coins-main-upper-section p {
        font-size: 12px;
    }

    .footer-inner {
        justify-content: center;
    }

    .delete-icon-admin-inner {
        height: 20px;
        width: 20px;
        right: 75px;
        bottom: -55px;
    }

    .profile-public-show {
        padding: 5px 15px;
    }

    .social-links-inner {
        padding: 5px;
        font-size: 15px;
    }

    .social-links-outer {
        padding-bottom: 5px;
    }

    .profile-public-container span {
        top: -13px;
    }

    .footer-inner {
        display: flex;
        flex-direction: column;
    }

    .social-links-container {
        justify-content: center;
    }

    .heplus-links-inner {
        line-height: 22px;
        font-size: 14px;
    }

    .logo-ps-footer {
        height: 35px;
        width: 125px;
    }

    .followus-footer {
        padding: 0px 0px 0px 9px;
        font-size: 15px;
    }

    .followus-separator {
        margin: 0px 0px 10px 9px;
        height: 4px;
    }

    .social-media-color-icon {
        height: 20px;
        width: 20px;
    }

    .social-follow-container {
        align-items: center;
    }

    .logo-footer {
        justify-content: center;
        display: none !important;
    }

    .help-us-links {
        padding: 10px 10px;
    }

    .heplus-links-inner a {
        justify-content: center;
    }

    .help-us-icon {
        display: none;
    }

    .inner-footer {
        padding-bottom: 0;
    }

    .draft-query {
        padding: 5px 5px;
        font-size: 16px;
        margin-left: 10px;
    }

    .draftqueries {
        padding: 0px 15px;
        font-size: 16px;
    }

    .banner-read-more {
        min-height: 200px;
    }

    .banner-read-more h1 {
        font-size: 18px !important;
    }

    .banner-read-more p {
        font-size: 13px;
        padding: 0px 10px;
        line-height: 18px;
    }

    .banner-read-more button {
        padding: 2px 13px;
        font-size: 13px;
    }

    .banner-first-image {
        height: 96%;
    }

    .banner-modal-heading {
        font-size: 16px !important;
    }

    .banner-data-read {
        font-size: 12px;
        line-height: 16px;
    }

    .banner-readmore-modal .modal-dialog .modal-content .modal-body {
        padding: 1rem;
    }

    .login-error a {
        padding: 5px 30px;
        font-size: 13px;
    }

    .maxRequestLength {
        margin-top: -14px;
    }

    .input-error-login {
        height: 35px;
        font-size: 12px;
    }

    .login-error-container textarea {
        font-size: 12px;
        height: 90px;
    }

    .blocked-resp-user {
        justify-content: space-evenly;
    }

    .blocked-user-start {
        padding: 30px 0px;
    }

    .delete-icon-query-final-draft {
        right: 73px;
        bottom: -53px;
        font-size: 14px;
    }

    .edit-icon-query-draft {
        right: 50px;
        bottom: -53px;
        font-size: 14px;
    }

    .draft-date {
        padding-bottom: 20px;
    }

    .fee-points-query {
        height: 8px;
        width: 8px;
        margin-right: 2px;
    }

    .points-heading {
        border-right: 50px solid transparent;
        border-top: 50px solid #0071bcc9;
    }

    .inner-points-heading {
        bottom: 25px;
        left: 3px;
        font-size: 10px;
    }

    .draft-anonymous {
        right: 3px !important;
    }

    .calenderIcon {
        font-size: 52px;
        top: 41px;
        right: 5px;
    }

    .calenderComponent {
        right: 2px;
        top: 91px;
    }

    .selected-user-fee {
        display: flex !important;
    }

    .draft-badge {
        right: 30px;
        bottom: 14px;
        font-size: 10px;
        padding: 0px 6px 0px 6px;
        top: unset;
        border-radius: 9px;
    }

    .change-preview-article img {
        height: 20px;
        width: 20px;
    }

    .edit-article-button {
        right: 40px;
        top: unset;
        padding: 1px 8px;
        font-size: 12px;
        bottom: 41px;
    }

    .selectUser {
        justify-content: center;
    }

    .sidebarSelectgold {
        margin: 0px 10px;
    }

    .poll_input .frm-inpt-inr {
        box-shadow: unset !important
    }

    .removeOption {
        padding: 5px 11px;
        height: 85%;
    }

    .polling-radio-btn {
        margin: 1rem 0 0rem 0;
    }

    .polling-btn-create .updt-prfile {
        padding: 6px 12px !important;
        font-size: 14px !important;
        width: 110px !important;
    }

    .poll-chart-button {
        left: 0px;
        top: 107px;
        font-size: 11px;
        padding: 2px 5px;
    }

    .dash-poll {
        padding: 1rem 1rem;
    }

    .dash-poll-head {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .dash-poll-inner {
        padding: 10px;
        margin: 11px 0px;
    }

    .dash-poll-label {
        font-size: 15px;
    }

    .box-checked-poll input {
        width: 1.1rem !important;
        height: 1.1rem !important;
    }

    .dash-poll-inner-border {
        transform: translateX(12px);
        padding: 10px;
        margin: 11px 0px;
    }

    .dash-poll-btn {
        padding: 3px 15px;
    }

    .dash-poll-img {
        height: 35px;
        width: 35px;
        margin-bottom: 10px;
    }

    .polling-radio-btn .btn-radio {
        width: auto !important;
    }

    .poll-ana-chart-button {
        left: 0;
        top: 76px;
        font-size: 11px;
        padding: 5px 5px 0px;
    }

    .poll-analytic-heading {
        font-size: 16px;
        padding: 6px 0 0;
    }

    .poll-ana-resp {
        justify-content: flex-start !important;
    }

}



@media only screen and (min-width: 768px) and (max-width:1024px) {
    .navbar-item-con {
        display: contents;
        width: auto;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        padding: 0;
        left: -55px !important;
    }

    .dropdown-menu {
        min-width: 7rem;
    }

    .set-intab {
        width: 33.3% !important;
    }

    .infinitescroll-dashboard {
        column-gap: 30px;
    }

    .dashbord-strt .dashbord-box .btn-qstn,
    .dashbord-strt .admin-dashbord-box .btn-qstn {
        padding: 6px 20px 6px;
    }

    .bnr-input .bnr-inputIcone {
        width: 80%;
    }

    .bnr-input .input-type {
        width: 100%;
    }

    .profle-mndiv {
        width: 94% !important;
        margin: 0px 25px !important;
    }

    .input-services-services input {
        border: 1px solid black;
    }

    .ps-profileyr .updat-yor {
        font-size: 28px !important;
        line-height: 0px !important;
        margin: 45px 0 45px 0 !important;
    }

    .frm-inpt-profle .frm-inpt-inr input {
        padding: 14px !important;
        font-size: 16px !important;
    }

    .ps-profileyr .profle-mndiv .prfl-img {
        height: 200px !important;
        width: 200px !important;
    }

    .ps-profileyr .servc-ps {
        margin-top: 60px !important;
    }

    .set-width-on-tab {
        width: 100% !important;
    }

    .new-width-set-tab {
        width: 70% !important;
    }



    .new-responsive-padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .sidebar-ps {
        padding: 0 10px;
    }

    .user-img-queries-sidebar {
        height: 50px;
        width: 50px;
    }

    .sidebar-ps .siderbar-box h2 {
        font-size: 16px;
    }

    .sidebar-ps .siderbar-box p {
        margin-bottom: 0px;
    }

    .sidebar-ps .siderbar-box .fees {
        color: #5d5d5d !important;
    }

    .button-spam {
        font-size: 12px;
        padding: 2px 4px;
        bottom: -42px;
        right: 28px;
    }

    .ps-name-box .input-grp-fil .frm-cntrol {
        height: 50px;
        font-size: 16px;
    }

    .ps-name-box .input-grp-fil .input-group-text {
        height: 50px;
        font-size: 16px;
    }

    .card-tab-responsive {
        width: 33.3% !important;
    }

    .sidebar-ps {
        height: 75vh;
        width: 100%;
    }

    .quries-clrfix .slct-name div li a {
        padding: 6px 8px;
    }

    .header-bg {
        background-size: cover;
    }

    .bnr-sec {
        margin-top: 60px;
    }

    .bnr-sec .bnr-text h1 {
        font-size: 35px;
        line-height: 35px;
    }

    .bnr-sec .bnr-text h4 {
        font-size: 24px;
    }

    .image-main-cards-dshbord {
        height: 50px;
        width: 50px;
    }

    .quries-clrfix {
        align-items: unset;
    }

    .quries-clrfix .btn-submit {
        font-size: 19px;
        padding: 6px 11px;
    }

    .ps-name-box {
        padding: 20px;
    }

    .get-position-set {
        width: 72% !important;
    }

    .sidebar-ps .gold-mmbr {
        font-size: 17px;
    }



    .userquery-tab-container ul li {
        margin-right: 0px;
    }

    .userquery-tab-container ul li button {
        margin-right: 3px;
        padding: 0px 10px;
    }

    .members-tab-gold li .nav-link {
        font-size: 14px;
        width: 80px;
        height: 66px;
    }

    .dashboard-resp-row {
        justify-content: center;
    }

    .delete-icon-modal-query {
        right: -13px;
        cursor: pointer;
    }

    .why-spam-heading {
        font-size: 22px;
    }

    .spam_submit_button {
        padding: 8px 20px;
    }

    .delete-icon-query-final {
        right: 5px;
        bottom: -32px;
        font-size: 16px;
    }

    .maxPageTitle {
        right: 6px;
        top: 17px;
        font-size: 13px;
    }

    .quries-clrfix .wt-clrfix {
        font-size: 16px;
    }

    .quries-clrfix .btn-radio .btn-radio-box {
        float: none;
    }

    .delete-modal-heading {
        font-size: 18px !important;
        margin-bottom: 30px;
    }

    .button-delete-modal-No {
        padding: 4px 17px;
    }

    .button-delete-modal {
        padding: 4px 17px;
    }

    .ps-name-box .title-text {
        width: 85%;
    }

    .dashbord-strt .dashbord-box p,
    .dashbord-strt .admin-dashbord-box p {
        font-size: 16px;
    }

    .float-design {
        position: absolute;
        right: 20px;
    }

    .new-class-active {
        margin-right: 0 !important;
    }

    .discussion-link-header {
        display: none;
    }

    .new-width-set-discussion {
        width: 70% !important;
    }

    .respo-button-mob-spam {
        font-size: 12px;
        padding: 0px 4px;
    }

    .article-ps .article-box .grl-musicn {
        height: 175px;
    }

    .article-ps .article-box h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .article-ps .article-box p {
        font-size: 15px;
        line-height: 21px;
    }

    .flex-center-add {
        display: block;
    }

    .article-ps .article-box .readmore {
        margin-top: 5px;
        font-size: 14px;
        width: 85%;
    }

    .article-ps .article-box .article-bx-inr h5 {
        font-size: 15px;
        padding: 8px 5px;
        margin-bottom: 0px;
    }

    .article-ps .article-box .article-bx-inr img {
        width: 35px;
        height: 35px;
    }

    .article-ps .article-box .article-bx-inr {
        bottom: 15px;
    }

    .article-ps .article-box {
        height: 435px;
    }

    .article-radio-btn {
        display: flex;
    }

    .article-view-title {
        font-size: 27px;
    }

    .new-name-box {
        width: 100%
    }

    .edit-icon-query {
        right: 31px;
        bottom: -34px;
        font-size: 18px;
    }

    .delete-icon-article-final {
        right: 27px !important;
        bottom: -17px !important;
        font-size: 16px !important;
    }

    .article-edit-icon-svg {
        bottom: -17px !important;
        right: 0 !important;
        font-size: 17px !important;
    }

    .cancel-button-edit-time,
    .article-cancel-edit {
        padding: 6px 5px !important;
        font-size: 17px !important;
    }

    .ps-name-box .title-text .undrline-txt {
        margin-bottom: 5px;
    }

    .eye-anonymousName {
        top: 20px;
        font-size: 18px;
    }

    .mood-chart-button {
        right: 5px !important;
        top: 100px !important;
        font-size: 15px;
        padding: 4px 7px;
    }

    .mood-chart-heading {
        width: 35% !important;
        font-size: 24px;
    }

    .mood-chart-outer {
        padding: 15px 0px;
    }

    .articles-slider .slick-slider .slick-next {
        right: -10px;
    }

    .articles-slider .slick-slider .slick-prev {
        left: -10px;
    }

    .delete-icon-article-outer {
        margin-top: 5px;
    }

    .img-name-articles {
        width: 100%;
    }

    .article-btn-container {
        width: 100%;
    }

    .banner-image {
        height: 380px !important;
    }

    .banner-stress {
        font-size: 33px !important;
    }

    .banner-consult {
        font-size: 43px !important;
    }

    .text-upper-section-bnr {
        width: 65% !important;
        height: 215px !important;
    }

    .tutorial-heading {
        padding: 10px 0px;
        font-size: 25px;
    }

    .tutorial-inner {
        padding: 40px 0px 0px 0px;
    }

    .tutorial-step {
        font-size: 24px;
        line-height: 15px;
        padding: 0px 0px 5px 0px;
    }

    .tutorial-head-inner,
    .tutorial-data,
    .tutorial-data span {
        font-size: 17px;
    }

    .tutorial-image {
        height: 265px;
    }

    .tutorial-conainer-inner .row .col-md-4 {
        width: 50%;
    }

    .tutorial-separator {
        margin: 30px 0px 40px 0px;
    }

    .member-others-sidebar li .nav-link {
        width: 100%;
        height: 50px;
    }

    .new-dropdown-inactive,
    .new-dropdown-active {
        padding: 2px 5px;
    }

    .gold-create-button,
    .active-create-button {
        padding: 5px 0px;
        border-radius: 12px;
        width: 49%;
        font-size: 12px;
    }

    .delete-icon-query-outer {
        right: -25px;
        bottom: -42px;
        height: 23px;
        width: 23px;
    }

    .button-spam-discussion {
        padding: 2px 4px;
        border-radius: 5px;
    }

    .add-bottom-position {
        bottom: -40px;
    }

    .article-upload-icon {
        font-size: 7rem;
    }

    .user-query-delete-tab {
        right: 0px;
    }

    .delete-icon-admin-inner {
        right: -23px;
    }

    .anonymous-radio-btn {
        padding-left: 23px !important;
    }

    .tab-section-responsive {
        display: none;
    }

    .thread-responsive {
        margin-top: 25px;
    }

    .article-reply-box {
        margin-bottom: 15px;
    }

    .social-media-color-icon {
        height: 25px;
        width: 25px;
    }

    .social-links-inner {
        padding: 7px;
    }

    .draft-query {
        padding: 6px 5px;
        font-size: 17px;
    }

    .draftqueries {
        padding: 0px 15px;
    }

    .banner-read-more p {
        font-size: 24px;
    }

    .banner-read-more h1 {
        font-size: 35px !important;
    }

    .dashbord-strt .dashbord-box,
    .admin-dashbord-box {
        padding: 33px 0px 0px;
    }

    .draft-date {
        padding-bottom: 35px !important;
    }

    .edit-icon-query-draft {
        right: 26px;
        bottom: -7px;
    }

    .delete-icon-query-final-draft {
        right: -4px;
        bottom: -7px;
    }

    .selectUser {
        display: block;
    }

    .sidebarSelectgold,
    .sidebarSelectother {
        margin: 5px 0 8px !important;
    }

    .sidebarViewButton {
        padding: 7px 8px;
    }

    .listpoll-container .col-md-3 {
        width: 50%;
    }

    .header-sec .container {
        padding: 0;
    }

    .discussion-link-view {
        display: block !important;
    }

    .dropdown-link-icon {
        display: none;
    }

    .poll-chart-button {
        left: 0px;
        font-size: 15px;
        padding: 5px 8px;
    }
}
